import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Fb from 'images/facebook-blue.svg';
import Instagram from 'images/instagram-blue.svg';
import linkedinLogo from 'images/linkedin-blue.svg';
import Zoom from 'images/zoom-blue.svg';
import LinkAccountModal from './LinkAccountModal';
import SocialLink from './SocialLink';
import { linkSocialMedia } from 'api/user';
import { LINKEDIN_OAUTH_CLIENT_ID, AUTHENTICATION_TYPE } from 'config/constants';
import { LinkedIn } from 'react-linkedin-login-oauth2';

class SocialMenu extends PureComponent {
    static propTypes = {
        apiCaller: PropTypes.func.isRequired,
        toggleSocial: PropTypes.func.isRequired,
        account: PropTypes.object,
        onUnlinkSocial: PropTypes.object
    }

    state = {
        isModalVisible: false,
        socialType: null
    }

    toggleModal = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible
        });
    }

    onSocialLoginSuccess = async (user, type) => {
        const { apiCaller } = this.props;

        if (user) {
            try {
                await apiCaller(linkSocialMedia, {
                    provider: type,
                    [type === AUTHENTICATION_TYPE.LINKEDIN ? 'code' : 'token']: user._token.accessToken
                });
            } catch (e) {
                console.error(e);
            }
        }
    }

    linkZoom = async () => {
        const clientId = 'CKAb8DOBSPyYJxiPYaOMBw';
        const redirectUri = 'https://360sport-staging.createl.la/';
        const responseType = 'code';
        const url = `https://zoom.us/oauth/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}`;

        window.open(url, '_blank');
    }

    render() {
        const { toggleSocial, account, onUnlinkSocial } = this.props;
        const { facebookId, instagramId, linkedinId, zoomAccessToken } = account.profile;

        return (
            <div className='Notifications-Menu pb-70'>
                <p className='Blue-Header__menu-title'>
                    <FontAwesomeIcon className='Blue-Header__menu-back' icon={faChevronLeft} color='#fff'
                        size={'sm'} onClick={toggleSocial} />
                    Social Medias
                </p>

                <div className='Notifications__list pb-0'>
                    <p className='Social__info'>Linking your accounts will enable to import your contacts or to share your classes on social medias.</p>
                </div>
                { account && (
                    <div>
                        <div className='Social__item'>
                            <img className='Social__item-icon' alt='Social' src={Fb}/>
                            <div className='Social__item-content'>
                                <p>Facebook</p>
                                { facebookId === null ? (
                                    <SocialLink
                                        className='Social__item-link'
                                        provider={AUTHENTICATION_TYPE.FACEBOOK}
                                        onLoginSuccess={(user) => {
                                            this.onSocialLoginSuccess(user, AUTHENTICATION_TYPE.FACEBOOK);
                                        }}
                                        onLoginFailure={console.error}>
                                        <span>Link</span>
                                    </SocialLink>
                                ) : (
                                    <span className='Social__item-link' onClick={() => {
                                        onUnlinkSocial(AUTHENTICATION_TYPE.FACEBOOK);
                                    }}>Unlink</span>
                                )}
                            </div>
                        </div>
                        <div className='Social__item'>
                            <img className='Social__item-icon' alt='Social' src={Instagram}/>
                            <div className='Social__item-content'>
                                <p>Instagram</p>
                                { instagramId === null ? (
                                    <SocialLink
                                        className='Social__item-link'
                                        provider={AUTHENTICATION_TYPE.GOOGLE}
                                        onLoginSuccess={(user) => {
                                            this.onSocialLoginSuccess(user, AUTHENTICATION_TYPE.INSTAGRAM);
                                        }}
                                        onLoginFailure={console.error}>
                                        <span>Link</span>
                                    </SocialLink>
                                ) : (
                                    <span className='Social__item-link' onClick={() => {
                                        this.unlinkSocial(AUTHENTICATION_TYPE.GOOGLE);
                                    }}>Unlink</span>
                                )}
                            </div>
                        </div>
                        <div className='Social__item'>
                            <img className='Social__item-icon' alt='Social' src={linkedinLogo}/>
                            <div className='Social__item-content'>
                                <p>Linkedin</p>
                                {linkedinId === null
                                    ? <LinkedIn
                                        className='Social__item-link'
                                        clientId={LINKEDIN_OAUTH_CLIENT_ID}
                                        onFailure={(error) => console.error(error)}
                                        onSuccess={(data) => {
                                            this.onSocialLoginSuccess(
                                                { _token: { accessToken: data.code } },
                                                AUTHENTICATION_TYPE.LINKEDIN);
                                        }}
                                        scope='r_liteprofile r_emailaddress w_member_social'
                                        redirectUri={`${window.location.origin}/auth/linkedin/callback`}>
                                        <span>Link</span>
                                    </LinkedIn>
                                    : <div>
                                        <span className='Social__item-link' onClick={() => {
                                            this.unlinkSocial(AUTHENTICATION_TYPE.LINKEDIN);
                                        }}>Unlink</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='Social__item'>
                            <img className='Social__item-icon' alt='Social' src={Zoom}/>
                            <div className='Social__item-content'>
                                <p>Zoom</p>
                                {zoomAccessToken === null ? (
                                    <span className='Social__item-link' onClick={this.linkZoom}>Link</span>
                                ) : (
                                    <span className='Social__item-link' onClick={onUnlinkSocial(AUTHENTICATION_TYPE.ZOOM)}>Unlink</span>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <LinkAccountModal
                    isModalVisible={this.state.isModalVisible}
                    socialType={this.state.socialType}
                    toggleModal={this.toggleModal} />
            </div>
        );
    }
}

export default withAsyncCaller(SocialMenu);
