import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Question from './Question';

export default class FaqItem extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        questions: PropTypes.array.isRequired
    }

    state = {
        isVisible: false
    }

    toggleQuestion = () => {
        this.setState({
            isVisible: !this.state.isVisible
        });
    }

    render() {
        return (
            <div>
                <div className='Faq__item' onClick={this.toggleQuestion}>
                    <p className='Faq__item-title'>{this.props.title}</p>
                    {this.state.isVisible
                        ? <FontAwesomeIcon className='Faq__item-chevron' icon={faChevronUp} color='#fff'
                            size={'sm'} /> : <FontAwesomeIcon className='Faq__item-chevron' icon={faChevronDown} color='#fff'
                            size={'sm'} />
                    }
                </div>
                {this.state.isVisible &&
                    <div className='Faq__item-dropdown'>
                        {this.props.questions.map((item, i) => <Question key={i} title={item.title} answer={item.answer}
                            link={item.link}/>)}
                    </div>}
            </div>
        );
    }
}
