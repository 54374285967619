import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Input, { THEMES } from 'components/Input/AppInput';
import './AutoSuggestInput.scss';

export { THEMES } from 'components/Input/AppInput';

export default class AutoSuggestInput extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        theme: PropTypes.oneOf(Object.keys(THEMES).map(key => THEMES[key])),
        inputProps: PropTypes.object,
        selection: PropTypes.shape({
            value: PropTypes.string.isRequired,
            isSelected: PropTypes.bool.isRequired
        }),
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired
        })),
        right: Input.propTypes.right,
        left: Input.propTypes.left,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        className: '',
        inputClassName: '',
        inputProps: {},
        theme: THEMES.PRIMARY
    }

    constructor(props) {
        super(props);
        this.rootNode = null;
        this.state = {
            drop: false,
            forceDrop: false
        };
    }

    componentDidMount() {
        window.addEventListener('click', this.handleWindowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleWindowClick);
    }

    MIN_LENGTH_TO_SUGGEST = 1;
    MAX_SUGGESTIONS = 4;

    handleWindowClick = e => {
        const { target } = e;
        const rootNode = this.rootNode;

        if (rootNode && !rootNode.contains(target)) {
            this.setState({
                drop: false,
                forceDrop: false
            });
        }
    }

    getSuggestions = () => {
        const { forceDrop } = this.state;
        const { options, selection } = this.props;
        const { value } = selection;

        return (value.length >= this.MIN_LENGTH_TO_SUGGEST ? (
            options.filter(
                option => option.label.substr(0, value.length).toLowerCase() === value.toLowerCase()
            )
        ) : (
            forceDrop ? options : []
        )).slice(0, this.MAX_SUGGESTIONS);
    }

    handleSelect = id => () => {
        const { onChange, options } = this.props;

        const option = options.find(option => option.id === id);

        onChange({
            value: option.label,
            isSelected: true
        }, option);
    }

    handleChange = e => {
        const { onChange } = this.props;
        const value = e.currentTarget.value;

        onChange({
            value,
            isSelected: false
        });
    }

    handleDrop = () => this.setState({
        drop: true
    });

    handleRightClick = e => this.setState({
        forceDrop: true
    });

    render() {
        const { drop, forceDrop } = this.state;
        const {
            className = '',
            inputProps,
            theme,
            selection,
            placeholder,
            onChange,
            options,
            right,
            left,
            disabled,
            ...others
        } = this.props;

        const suggestions = this.getSuggestions();

        const { value, isSelected } = selection;

        const dropDown = (drop || forceDrop) && !isSelected;

        return (
            <span
                ref={node => (this.rootNode = node)}
                {...others}
                className={`
                    AutoSuggestInput
                    ${className}
                `}>
                <Input
                    {...inputProps}
                    disabled={disabled}
                    className={`
                        AutoSuggestInput__input
                        ${inputProps.className}
                    `}
                    autoComplete={false}
                    theme={theme}
                    value={value}
                    right={right}
                    left={left}
                    onRightClick={this.handleRightClick}
                    placeholder={placeholder}
                    onChange={this.handleChange}
                    onFocus={this.handleDrop} />
                {dropDown && !disabled && (
                    <div className='AutoSuggestInput__dropdown'>
                        {suggestions && suggestions.map(suggestion => (
                            <span
                                key={suggestion.id}
                                className='AutoSuggestInput__dropdown-item'
                                onClick={this.handleSelect(suggestion.id)}>
                                {suggestion.label}
                            </span>
                        ))}
                    </div>
                )}
            </span>
        );
    }
}
