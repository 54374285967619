import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import SupportIcon from 'images/support-icon.svg';
import DeleteAccountModal from './DeleteAccountModal';

export default class Support extends Component {
    static propTypes = {
        account: PropTypes.object,
        selectedCompany: PropTypes.object,
        toggleSupport: PropTypes.func.isRequired,
        onLogout: PropTypes.func,
        onDeleteAccount: PropTypes.func,
        isSubmitLoading: PropTypes.object,
        formSuccess: PropTypes.object
    }

    state = {
        isModalVisible: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.formSuccess?.deleteAccount) {
                this.setState({ isModalVisible: false });
            }
        }
    }

    toggleModal = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible
        });
    }

    sendMail = () => {
        window.open('mailto:info@360wellness.io', '_blank');
    }

    render() {
        const { account, selectedCompany, toggleSupport } = this.props;

        return (
            <div className='Notifications-Menu pb-70'>
                <p className='Blue-Header__menu-title'>
                    <FontAwesomeIcon className='Blue-Header__menu-back' icon={faChevronLeft} color='#fff'
                        size={'sm'} onClick={toggleSupport} />
                      Support
                </p>
                <div className='Support__content'>
                    <div>
                        <p className='Support__header-text'>If you have a question, please contact us at:</p>
                        <div className='flex-row flex-center'>
                            <p className='Support__header-icon'>@</p>
                            <p className='Support__header-link' onClick={() => this.sendMail()}>info@360wellness.io</p>
                        </div>
                    </div>
                    <div className='flex-center flex-col'>
                        <img className='Support__footer-icon' alt='Support' src={SupportIcon}/>
                        <p className='Support__footer-text'>v 1.0.0.13</p>
                        <div className='Support__footer-button' onClick={this.props.onLogout}>Logout</div>
                        <p className='Support__footer-link' onClick={this.toggleModal}>Delete account</p>
                    </div>
                </div>
                <DeleteAccountModal
                    account={account}
                    selectedCompany={selectedCompany}
                    isModalVisible={this.state.isModalVisible}
                    toggleModal={this.toggleModal}
                    onDelete={this.props.onDeleteAccount}
                    isSubmitLoading={this.props.isSubmitLoading}/>
            </div>
        );
    }
}
