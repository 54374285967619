import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SafeImage.scss';

const SafeImage = props => {
    const {
        className = '',
        textClassName = '',
        src,
        alt = '',
        icon,
        placeholder,
        isRound,
        isSquareRound,
        size,
        style = {},
        ...others
    } = props;

    return (
        <div
            {...others}
            className={`
                SafeImage
                ${className}
                ${isRound ? 'SafeImage--isRound' : ''}
                ${isSquareRound ? 'SafeImage--isSquareRound' : ''}
            `}
            style={{
                ...style,
                height: size,
                width: size,
                fontSize: 0.6 * size
            }} >
            {(icon || src) ? (
                src ? (
                    <img className='SafeImage__img' src={src} alt={alt}/>
                ) : (
                    <FontAwesomeIcon
                        className='SafeImage__img'
                        style={{ fontSize: 0.7 * size }}
                        icon={icon} />
                )
            ) : (
                <span
                    className={`
                        SafeImage__text
                        ${textClassName}
                    `}>
                    {placeholder || alt[0]}
                </span>
            )}
        </div>
    );
};

SafeImage.propTypes = {
    className: PropTypes.string,
    textClassName: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string.isRequired,
    icon: PropTypes.object,
    placeholder: PropTypes.string,
    isRound: PropTypes.bool,
    isSquareRound: PropTypes.bool,
    size: PropTypes.number,
    style: PropTypes.object
};

export default SafeImage;
