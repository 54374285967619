import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import AppAlertsCard from './components/Card/AppAlertsCard';
import { ALERT_TYPES, ALERT_POSITION, dismissAlert } from 'redux/reducers/alerts';
import './AppAlerts.scss';

class AppAlerts extends PureComponent {
    static propTypes = {
        position: PropTypes.oneOf(Object.keys(ALERT_POSITION).map(key => ALERT_POSITION[key])).isRequired,
        dismissAlert: PropTypes.func.isRequired,
        alerts: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                icon: PropTypes.string.isRequired,
                icon2: PropTypes.string.isRequired,
                subText: PropTypes.any.isRequired,
                text: PropTypes.any.isRequired,
                type: PropTypes.string.isRequired,
                position: PropTypes.string.isRequired,
                onDismiss: PropTypes.func.isRequired,
                isAutoDismiss: PropTypes.bool.isRequired,
                animationDuration: PropTypes.number.isRequired,
                dismissDuration: PropTypes.number.isRequired,
                renderTimeDisplay: PropTypes.func
            }).isRequired
        ).isRequired,
        isAutoDismiss: PropTypes.bool,
        animationDuration: PropTypes.number,
        dismissDuration: PropTypes.number,
        renderTimeDisplay: PropTypes.func
    }

    static defaultProps = {
        isAutoDismiss: true,
        animationDuration: 500,
        dismissDuration: 4000
    }

    renderAlert = ({ id, ...props }) => {
        const {
            dismissAlert, position, renderTimeDisplay,
            isAutoDismiss, animationDuration, dismissDuration
        } = this.props;

        return (
            <AppAlertsCard
                isAutoDismiss={isAutoDismiss}
                animationDuration={animationDuration}
                dismissDuration={dismissDuration}
                key={id}
                id={id}
                position={position}
                onDismiss={dismissAlert}
                renderTimeDisplay={renderTimeDisplay}
                {...props} />
        );
    }

    render() {
        const { alerts, position } = this.props;

        return createPortal(
            <div className={`AppAlerts AppAlerts--${position}`}>
                {alerts.map(this.renderAlert)}
            </div>,
            document.body
        );
    }
}

function mapStateToProps({ alerts }) {
    return {
        ...alerts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dismissAlert: (id) => {
            dispatch(dismissAlert(id));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAlerts);
