import React, {useCallback, useEffect, useRef, useState} from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import NotificationItem from './NotificationItem';
import PreferencesItem from '../Preferences/PreferencesItem';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';
import COMPANY from 'assets/icons/dashboard-company.svg';
import CLASSES from 'assets/icons/dashboard-classes.svg';
import ON_DEMAND from 'assets/icons/dashboard-ondemand.svg';
import FINANCES from 'assets/icons/dashboard-finances.svg';
import CLIENTS from 'assets/icons/dashboard-clients.svg';

NotificationsMenu.propTypes = {
    isFetching: PropTypes.bool,
    activeTab: PropTypes.string.isRequired,
    selectTab: PropTypes.func.isRequired,
    selectedCompany: PropTypes.object,
    toggleNotifications: PropTypes.func.isRequired,
    preferences: PropTypes.object,
    notifications: PropTypes.object,
    onUpdatePreference: PropTypes.func.isRequired,
    onReadNotification: PropTypes.func
};

const PREFERENCES = {
    company: {
        title: 'Company',
        items: {
            members_profile: { title: 'Members Profile', value: false },
            clients_list: { title: 'Client List', value: false },
            invitations: { title: 'Invitations', value: false }
        },
        icon: COMPANY
    },
    classes: {
        title: 'Classes',
        items: {
            new_classes: { title: 'New Classes', value: false },
            start_classes: { title: 'Start Classes', value: false }
        },
        icon: CLASSES
    },
    'on-demand': {
        title: 'On Demand',
        items: {
            availabilities: { title: 'Availablilities', value: false }
        },
        icon: ON_DEMAND
    },
    finance: {
        title: 'Finances',
        items: {
            invoices: { title: 'Invoices', value: false }
        },
        icon: FINANCES
    },
    clients: {
        title: 'Clients',
        items: {
            invitation: { title: 'Invitation', value: false },
            classes_reminder: { title: 'Classes Reminder', value: false },
            company_offers: { title: 'Company Offers', value: false }
        },
        icon: CLIENTS
    }
};

export default function NotificationsMenu(props) {
    const {
        toggleNotifications, activeTab, selectTab, isFetching, selectedCompany,
        notifications, preferences, onUpdatePreference, onReadNotification
    } = props;
    const installedApps = selectedCompany?.installedAppIds ?? [];
    const [preferenceValues, setPreferenceValues] = useState(PREFERENCES);
    const [notificationItems, setNotificationItems] = useState(notifications);
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            getPreferenceValue();

            const prefs = {};
            installedApps.forEach(item => {
                const pref = Object.keys(PREFERENCES).find(i => PREFERENCES[i].title === item);

                if (pref) {
                    prefs[pref] = PREFERENCES[pref]
                }
            });

            setPreferenceValues(prefs);
        }

        mounted.current = true;
    }, [installedApps]);

    const getPreferenceValue = useCallback(() => {
        if (preferences) {
            const prefs = PREFERENCES;

            Object.keys(prefs).forEach(i => {
                if (preferences[i]) {
                    Object.keys(preferences[i]).forEach(j => {
                        if (prefs[i].items[j]) {
                            prefs[i].items[j].value = preferences[i][j];
                        }
                    });
                }
            });

            setPreferenceValues(prefs);
        }
    });

    const handleUpdatePreference = (name, data) => {
        setPreferenceValues({
            ...preferenceValues,
            [name]: {
                ...preferenceValues[name],
                items: data
            }
        });

        const newPrefs = {};

        Object.keys(preferenceValues).forEach(key => {
            newPrefs[key] = {};

            if (key === name) {
                Object.keys(data).forEach(i => {
                    const { value } = data[i];

                    newPrefs[key][i] = value;
                });
            } else {
                Object.keys(preferenceValues[key].items).forEach(i => {
                    const { value } = preferenceValues[key].items[i];

                    newPrefs[key][i] = value;
                });
            }

            newPrefs[key] = [newPrefs[key]];
        });

        onUpdatePreference(newPrefs);
    };

    const handleReadNotification = (item) => {
        const data = {
            notification_ids: [item.id]
        };

        const removedNotifications = notificationItems.filter(notif => notif.id !== item.id);

        setNotificationItems(removedNotifications);

        onReadNotification(data);
    };

    return (
        <div className='Notifications-Menu'>
            <p className='Blue-Header__menu-title'>
                <FontAwesomeIcon className='Blue-Header__menu-back' icon={faChevronLeft} color='#fff'
                    size={'sm'} onClick={toggleNotifications} />
                Notifications
            </p>

            { isFetching ? (
                <ActivityIndicator size={40}/>
            ) : (
                <div>
                    <div className='Notifications-Menu__tabs'>
                        <div className={`${activeTab === 'list' ? '-is-active' : ''} Notifications-Menu__tab`} onClick={() => selectTab('list')}>
                            List
                        </div>
                        <div className={`${activeTab === 'preferences' ? '-is-active' : ''} Notifications-Menu__tab`} onClick={() => selectTab('preferences')}>
                            Preferences
                        </div>
                    </div>

                    {activeTab === 'list'
                        ? <div className='Notifications__list'>
                            {notificationItems?.map((item, i) => (
                                <NotificationItem
                                    key={i}
                                    image={item.icon}
                                    title={item.reference_type}
                                    subtitle={item.title}
                                    time={item.start_date}
                                    notification={item.description}
                                    action={item.title}
                                    isNew={true}
                                    onReadNotification={() => handleReadNotification(item)}/>
                            ))}
                        </div>
                        : <div className='Notifications__list px-0'>
                            <p className='Preferences__info'>Do you want to be notified when:</p>

                            {Object.keys(preferenceValues).map(key => (
                                <PreferencesItem
                                    key={key}
                                    name={key}
                                    title={preferenceValues[key].title}
                                    items={preferenceValues[key].items}
                                    icon={preferenceValues[key].icon}
                                    onChange={handleUpdatePreference}/>
                            ))}
                        </div>}
                </div>
            ) }
        </div>
    );
}
