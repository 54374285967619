import firebase from 'firebase/app';
import { STORAGEKEY_FCM_TOKEN, FIREBASE_CONFIG } from 'config/constants';

/**
 * return true if messaging is supported
 * @returns {Boolean}
 */
function isFcmSupported() {
    return firebase.messaging.isSupported();
}

/**
 * subscribe to FCM
 * @param  {Function} onMessage
 * @param {Boolean} forceGenration
 * @returns {Promise}
 */
export const subscribeFcm = async (onMessage, forceGenration) => {
    const currentToken = getFcmToken();

    if (!isFcmSupported()) {
        return;
    }

    const messaging = firebase.messaging();

    messaging.onMessage(onMessage);

    if (!forceGenration && currentToken) {
        return false;
    }

    await unsubscribeFcm();

    try {
        const token = await messaging.getToken({ vapidKey: FIREBASE_CONFIG.FCM_VAPID });

        return token;
    } catch (e) {
        return false;
    }
};

/**
 * subscribe from FCM
 * @returns {Promise}
 */
export const unsubscribeFcm = async () => {
    if (!isFcmSupported()) {
        return;
    }

    const messaging = firebase.messaging();

    try {
        await messaging.deleteToken();
    } catch (err) {
        console.error(err);
    }

    setFcmToken('');
};

/**
 * Get FCM token
 * @returns {String}
 */
export function getFcmToken() {
    return localStorage.getItem(STORAGEKEY_FCM_TOKEN);
}

/**
 * Set FCM token
 * @param {String} token
 * @returns {String}
 */
export function setFcmToken(token) {
    return localStorage.setItem(STORAGEKEY_FCM_TOKEN, token);
}
