import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    handleSelectedCompanyChange,
    selectStoreCompany,
    handleUninstallApp,
    handleLoadUserCompanies
} from 'redux/reducers/company';
import HeaderNav from 'components/HeaderNav/AppHeaderNav';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import {
    deleteCompany,
    grantPermissions,
    publishCompany,
    quitFromCompany,
    unpublishCompany, updateCoach
} from 'api/company';
import { deleteUser, linkSocialMedia, unLinkSocialMedia, updateUserProfile } from 'api/user';
import { APP_IDS } from 'config/constants';
import { handleLogout, handleUpdateUserProfile } from '../../../../redux/reducers/auth';
import { sendInvitation } from '../../../../api/invitation';
import { pushAlert, ALERT_TYPES } from '../../../../redux/reducers/alerts';
import {
    getUnreadUserNotification,
    getUserPreference,
    updateReadUserNotification,
    updateUserPreference
} from '../../../../api/notification';
import { uploadImage } from '../../../../api/upload';
import { fetchCompanyCoaches } from '../../../../api/coach';

class AppHeaderNavContainer extends Component {
    static propTypes = {
        isSub: HeaderNav.propTypes.isSub,
        title: HeaderNav.propTypes.title,

        history: PropTypes.object,

        // --connect--
        companies: PropTypes.array.isRequired,
        selectedCompanyId: PropTypes.string.isRequired,
        changeSelectedCompany: PropTypes.func.isRequired,
        profile: PropTypes.object,
        logoutAccount: PropTypes.func,
        selectedCompany: PropTypes.object,
        pushAlert: PropTypes.func,
        updateProfile: PropTypes.func,

        // --withAsyncCaller--
        apiCaller: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            showSide: false,
            preferences: null,
            notifications: null,
            company: {},
            account: this.props.profile,
            publish: {},
            logout: {},
            isSubmitLoading: {
                preference: false,
                deleteAccount: false,
                deleteCompany: false,
                quitCompany: false,
                inviteCoach: false,
                updateProfile: false
            },
            formSuccess: { deleteAccount: null, quitCompany: null, inviteCoach: null, social: null, deleteCompany: null },
            formError: { quitCompany: null }
        };
    }

    componentDidMount() {
        this.getPreference();
        this.getUnreadNotification();

        let query = window.location.href;

        query = query.split('?');

        if (query.length > 1) {
            const urlParams = new URLSearchParams(query[1]);

            if (urlParams.get('code')) {
                this.linkSocialMedia({ provider: 'zoom', code: urlParams.get('code') });
            }
        }
    }

    getPreference = async () => {
        const { apiCaller } = this.props;

        this.setState({
            isFetching: true
        });

        try {
            const response = await apiCaller(getUserPreference);

            this.setState({
                preferences: response.data.data.preferences,
                isFetching: false
            });
        } catch (e) {
            if (e) {
                this.setState({
                    isFetching: false
                });
                console.error(e);
            }
        }
    }

    updatePreference = async (data) => {
        const { apiCaller } = this.props;

        this.setState({
            isSubmitLoading: { ...this.state.isSubmitLoading, preference: true }
        });

        try {
            await apiCaller(updateUserPreference, data);

            this.setState({
                isSubmitLoading: { ...this.state.isSubmitLoading, preference: false }
            });
        } catch (e) {
            if (e) {
                this.setState({
                    isSubmitLoading: { ...this.state.isSubmitLoading, preference: true }
                });
                console.error(e);
            }
        }
    }

    getUnreadNotification = async () => {
        const { apiCaller } = this.props;

        this.setState({
            isFetching: true
        });

        try {
            const response = await apiCaller(getUnreadUserNotification);

            this.setState({
                notifications: response.data.data,
                isFetching: false
            });
        } catch (e) {
            if (e) {
                this.setState({
                    isFetching: false
                });
                console.error(e);
            }
        }
    }

    updateNotification = async (data) => {
        const { apiCaller } = this.props;

        try {
            const response = await apiCaller(updateReadUserNotification, data);

            if (response.data.data.status === 'success') {
                this.getUnreadNotification();
            }
        } catch (e) {
            console.error(e);
        }
    }

    logoutAccount = async () => {
        const { logoutAccount } = this.props;

        try {
            logoutAccount();
        } catch (e) {
            console.error(e);
        }
    }

    deleteAccount = async (id) => {
        const { apiCaller, logoutAccount } = this.props;

        this.setState({
            isSubmitLoading: { ...this.state.isSubmitLoading, deleteAccount: true }
        });

        try {
            const response = await apiCaller(deleteUser, id);

            if (response.status === 200 || response.status === 204) {
                logoutAccount();

                this.setState({
                    isSubmitLoading: { ...this.state.isSubmitLoading, deleteAccount: false }
                });
            }
        } catch (e) {
            this.setState({
                isSubmitLoading: { ...this.state.isSubmitLoading, deleteAccount: false }
            });

            console.error(e);
        }
    }

    deleteCompany = async (id) => {
        const { apiCaller } = this.props;

        this.setState({
            isSubmitLoading: { ...this.state.isSubmitLoading, deleteCompany: true }
        });

        try {
            const coaches = await apiCaller(fetchCompanyCoaches, id);

            if (coaches.data.data) {
                coaches.data.data.forEach(async (item) => {
                    await apiCaller(quitFromCompany, item.id);
                });
            }

            const response = await apiCaller(deleteCompany, id);

            if (response.data.status === 'success') {
                this.setState({
                    isSubmitLoading: { ...this.state.isSubmitLoading, deleteCompany: false },
                    formSuccess: { ...this.state.formSuccess, deleteCompany: 'Success' }
                });

                this.getProfile();
            }

            await handleUninstallApp(APP_IDS.COMPANY);
        } catch (e) {
            console.error(e);

            this.setState({
                isSubmitLoading: { ...this.state.isSubmitLoading, deleteCompany: false }
            });
        }
    }

    publishCompany = async (id) => {
        const { apiCaller } = this.props;

        try {
            await apiCaller(updateUserProfile(this.state.account.id));

            const response = await apiCaller(publishCompany, id);

            if (response.data.status === 'success') {
                this.getProfile();
                this.setState({
                    showSide: false
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    unpublishCompany = async (id) => {
        const { apiCaller } = this.props;

        try {
            const response = await apiCaller(unpublishCompany, id);

            if (response.data.data.status === 'success') {
                this.getProfile();
            }
        } catch (e) {
            console.error(e);
        }
    }

    quitCompany = async (id) => {
        const { apiCaller, loadUserCompanies } = this.props;

        this.setState({
            isSubmitLoading: { ...this.state.isSubmitLoading, quitCompany: true }
        });

        try {
            const response = await apiCaller(quitFromCompany, id);

            if (response.data.status === 'success') {
                this.setState({
                    formSuccess: { ...this.state.formSuccess, quitCompany: 'Success' },
                    isSubmitLoading: { ...this.state.isSubmitLoading, quitCompany: false }
                });

                loadUserCompanies();
            }
        } catch (e) {
            console.error(e);

            this.setState({
                isSubmitLoading: { ...this.state.isSubmitLoading, quitCompany: false },
                formError: { ...this.state.formError, quitCompany: 'Something wrong!' }
            });
        }
    }

    updateAccountProfile = async (id, data) => {
        const { apiCaller, updateProfile, selectedCompanyId } = this.props;

        this.setState({
            isSubmitLoading: { ...this.state.isSubmitLoading, updateProfile: true }
        });

        try {
            if (data.profile_picture instanceof File) {
                const imgResponse = await apiCaller(uploadImage, data.profile_picture);

                data.profile_picture = imgResponse.data;
            }

            updateProfile(id, data);

            const response = await apiCaller(updateCoach, selectedCompanyId, data);

            setTimeout(() => {
                this.setState({
                    isSubmitLoading: { ...this.state.isSubmitLoading, updateProfile: false }
                });
            }, 2000);
        } catch (e) {
            console.error(e);

            this.setState({
                isSubmitLoading: { ...this.state.isSubmitLoading, updateProfile: false }
            });
        }
    }

    grantCoachPermissions = async (id, data) => {
        const { apiCaller, pushAlert } = this.props;

        try {
            const response = await apiCaller(grantPermissions, id, data);

            if (response.status === 'success') {
                pushAlert({
                    text: 'About your staff',
                    subText: 'Coach permissions has been updated',
                    type: ALERT_TYPES.SUCCESS
                });

                this.getProfile();
            }
        } catch (e) {
            console.error(e);
        }
    }

    sendClientsInvite = async (data) => {
        const { apiCaller, selectedCompany } = this.props;
        const form = {
            ...data,
            company_id: selectedCompany.id
        };

        this.setState({
            isSubmitLoading: { ...this.state.isSubmitLoading, inviteCoach: true }
        });

        try {
            const response = await apiCaller(sendInvitation, form);

            if (response.data.status === 'success') {
                this.setState({
                    isSubmitLoading: { ...this.state.isSubmitLoading, inviteCoach: false },
                    formSuccess: { ...this.state.formSuccess, inviteCoach: 'Success invite clients' }
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    linkSocialMedia = async (data) => {
        const { apiCaller } = this.props;

        try {
            await apiCaller(linkSocialMedia, data);
        } catch (e) {
            console.error(e);
        }
    }

    unlinkSocial = async (type) => {
        const { apiCaller } = this.props;

        this.setState({
            isFetching: true
        });

        try {
            const response = await apiCaller(unLinkSocialMedia, type);

            if (response.data.data.status === 'success') {
                this.setState({
                    isFetching: false
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleHeaderItemSelect = (id, type) => {
        const { changeSelectedCompany } = this.props;

        changeSelectedCompany(id);
    }

    render() {
        const {
            // --connect--
            selectedCompanyId,
            changeSelectedCompany,
            companies,

            history,
            ...others
        } = this.props;

        const { account, isFetching, isSubmitLoading, formSuccess, preferences, notifications, formError } = this.state;

        return (
            <HeaderNav
                {...others}
                isFetching={isFetching}
                companies={companies}
                selectedCompanyId={selectedCompanyId}
                onItemSelect={this.handleHeaderItemSelect}
                account={account}
                history={history}
                preferences={preferences}
                notifications={notifications}
                onUpdatePreference={this.updatePreference}
                onReadNotification={this.updateNotification}
                onLogout={() => this.logoutAccount()}
                onDeleteCompany={(id) => this.deleteCompany(id)}
                onDeleteAccount={() => this.deleteAccount(account.user_id)}
                onUpdateProfile={(id, data) => this.updateAccountProfile(id, data)}
                onPublishCompany={(id) => this.publishCompany(id)}
                onUnpublishCompany={(id) => this.unpublishCompany(id)}
                onQuitCompany={(id) => this.quitCompany(id)}
                onGrantPermissions={(id, data) => this.grantCoachPermissions(id, data)}
                onSendClientsInvite={this.sendClientsInvite}
                onUnlinkSocial={this.unlinkSocial}
                isSubmitLoading={isSubmitLoading}
                formSuccess={formSuccess}
                formError={formError}/>
        );
    }
}

const mapStateToProps = state => {
    const { selectedCompanyId, companies } = selectStoreCompany(state);
    const company = companies.find(company => company.id === selectedCompanyId);
    const profile = state.auth.profile.data ?? {};

    return {
        companies,
        selectedCompanyId,
        profile: {
            ...company?.coach_profile,
            residence: profile.residence,
            spoken_language: profile.spoken_language,
            postal_code: profile.postal_code,
            phone_number: profile.phone_number,
            timezone: profile.timezone,
            gender: profile.gender,
            created_at: profile.created_at,
            profile: {
                facebookId: profile?.profile?.facebookId,
                instagramId: profile?.profile?.instagramId,
                linkedinId: profile?.profile?.linkedinId,
                zoomAccessToken: profile?.profile?.zoomAccessToken
            }
        }
    };
};

const mapDispatchToProps = {
    changeSelectedCompany: handleSelectedCompanyChange,
    deleteApp: handleUninstallApp,
    logoutAccount: handleLogout,
    updateProfile: handleUpdateUserProfile,
    loadUserCompanies: handleLoadUserCompanies
};

const enhance = compose(
    withAsyncCaller,
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AppHeaderNavContainer);
