import { getCategories } from '../../api/category';

const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

const fetchCategories = () => ({
    type: FETCH_CATEGORIES
});

const fetchCategoriesSuccess = (categories) => ({
    type: FETCH_CATEGORIES_SUCCESS,
    categories
});

const fetchCategoriesFailure = () => ({
    type: FETCH_CATEGORIES_FAILURE
});

export function handlefetchCategories() {
    return async (dispatch) => {
        try {
            dispatch(fetchCategories());

            const categories = await getCategories();

            dispatch(fetchCategoriesSuccess(categories.data.data));
        } catch (e) {
            dispatch(fetchCategoriesFailure());
            console.error(e);
        }
    };
}

const INITIAL_STATE = {
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES:
            return {
                ...state,
                loading: true
            };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.categories
            };
        case FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                errors: action.errors
            };
        default:
            return state;
    }
};
