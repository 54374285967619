import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Preference from './Preference';
import Toggle from 'react-toggle';

export default class PreferencesItem extends Component {
    static propTypes = {
        name: PropTypes.string,
        title: PropTypes.string.isRequired,
        items: PropTypes.object,
        icon: PropTypes.object,
        onChange: PropTypes.func
    }

    state = {
        showMore: false,
        allCheck: false,
        items: []
    };

    componentDidMount() {
        if (this.props.items) {
            const allCheck = Object.keys(this.props.items).filter(i => this.props.items[i].value === true);

            this.setState({
                allCheck: allCheck.length === Object.keys(this.props.items).length && true,
                items: this.props.items
            });
        }
    }

    toggleMore = () => {
        this.setState({
            showMore: !this.state.showMore
        });
    }

    handleChangeToggle = (name, title, value) => {
        const newItems = {
            ...this.state.items,
            [name]: {
                title: title,
                value: value
            }
        };

        this.setState({
            items: newItems
        }, () => {
            const allCheck = Object.keys(this.state.items).filter(i => this.state.items[i].value === true);

            this.setState({
                allCheck: allCheck.length === Object.keys(this.state.items).length && true
            });

            this.handleSubmitModify();
        });
    }

    handleChangeAllToggle = () => {
        const { allCheck, items } = this.state;

        Object.keys(items).forEach(key => {
            items[key].value = !allCheck;
        });

        this.setState({
            allCheck: !allCheck,
            items: items
        }, () => {
            this.handleSubmitModify();
        });
    }

    handleSubmitModify = () => {
        const { name } = this.props;
        const { items } = this.state;

        this.props.onChange(name, items);
    }

    render() {
        const { showMore, allCheck, items } = this.state;
        const { title, icon } = this.props;

        return (
            <>
                <div className='Preferences__item'>
                    <div className='d-flex flex-ac'>
                        <div className='Notifications__item-user'>
                            <img src={icon} alt='user' />
                        </div>

                        <p className='Notifications__item-title'>{title}</p>
                    </div>

                    <div className='d-flex flex-ac'>
                        <Toggle
                            defaultChecked={allCheck}
                            checked={allCheck}
                            icons={false}
                            onChange={this.handleChangeAllToggle} />

                        <FontAwesomeIcon
                            className='Preferences__icon'
                            icon={showMore ? faChevronUp : faChevronDown} color='#58595B'
                            onClick={this.toggleMore}
                            size={'xl'} />
                    </div>
                </div>
                {showMore && <>
                    {Object.keys(items).map(key => (
                        <Preference
                            key={key}
                            title={items[key].title}
                            name={key}
                            checked={items[key].value}
                            onChange={this.handleChangeToggle}/>
                    ))}
                </>}
            </>
        );
    }
}
