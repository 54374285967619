import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SocialLogin from 'react-social-login';
import Button from 'components/Button/AppButton';
import { FACEBOOK_APP_ID, GOOGLE_OAUTH_CLIENT_ID, AUTHENTICATION_TYPE } from 'config/constants';

export { THEMES } from 'components/Button/AppButton';

const MAP_PROVIDER_TO_APP_ID = {
    [AUTHENTICATION_TYPE.FACEBOOK]: FACEBOOK_APP_ID,
    [AUTHENTICATION_TYPE.GOOGLE]: GOOGLE_OAUTH_CLIENT_ID
};

class SocialLink extends PureComponent {
    static propTypes = {
        // react-social-login
        triggerLogin: PropTypes.func.isRequired
    }

    render() {
        const { triggerLogin, ...others } = this.props;

        return (
            <span
                onClick={triggerLogin}
                {...others} />
        );
    }
}

const SocialLinkWithHOC = SocialLogin(SocialLink);

const SocialLinkWrapper = props => {
    const {
        className,
        provider,
        ...others
    } = props;

    return (
        <SocialLinkWithHOC
            key={Date.now()}
            className={className}
            provider={provider}
            appId={MAP_PROVIDER_TO_APP_ID[provider]}
            {...others} />
    );
};

SocialLinkWrapper.propTypes = {
    className: PropTypes.string,
    onLoginSuccess: PropTypes.func,
    onLoginFailure: PropTypes.func,
    provider: PropTypes.oneOf([
        AUTHENTICATION_TYPE.FACEBOOK,
        AUTHENTICATION_TYPE.GOOGLE,
        AUTHENTICATION_TYPE.INSTAGRAM,
        AUTHENTICATION_TYPE.LINKEDIN,
        AUTHENTICATION_TYPE.TWITTER,
        AUTHENTICATION_TYPE.WHATSAPP
    ]).isRequired,
    theme: Button.propTypes.theme
};

export default SocialLinkWrapper;
