/* eslint-disable react/jsx-max-props-per-line */

import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import AppLoading from '../Loading/AppLoading';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'config/constants';
import { AuthedRoute, UnauthedRoute } from './components/RouteWrappers';
import {
    LoginContainer, SignupContainer, CompanyCreationContainer, CreateProfileContainer, DashboardContainer,
    CompanyContainer,
    ClassesContainer,
    TestingContainer,
    ClientsContainer,
    PageNotFoundContainer,
    OnDemandContainer,
    FinancesContainer,
    ShareContainer
} from './config/routes';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

// TODO_REPLACE(
//  Route: accessible for anyone
//  UnauthedRoute: accessible for unauthed clients only
//  AuthedRoute: accessible for authed clients only
// )

export default class AppRouter extends Component {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired
    }

    shouldComponentUpdate(nextProps) {
        const { isAuthed } = this.props;

        if (isAuthed !== nextProps.isAuthed) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed } = this.props;

        return (
            <Suspense fallback={<AppLoading />}>
                <Switch>
                    <UnauthedRoute path={ROUTES.LOGIN} exact={true} component={LoginContainer} isAuthed={isAuthed} />
                    <UnauthedRoute path={ROUTES.SIGNUP} exact={true} component={SignupContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.COMPANY_CREATION} exact={true} component={CompanyCreationContainer} isAuthed={isAuthed} />
                    <Route path={ROUTES.INVITED_COACH} exact={true} component={CreateProfileContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.CREATE_PROFILE} exact={true} component={CreateProfileContainer} isAuthed={isAuthed} />
                    <AuthedRoute path={ROUTES.DASHBOARD} exact={true} component={DashboardContainer} isAuthed={isAuthed} />

                    <AuthedRoute path={ROUTES.CLASSES} exact={true} isAuthed={isAuthed} component={ClassesContainer} />
                    <Route exact={true} path='/auth/linkedin/callback' component={LinkedInPopUp} />
                    <AuthedRoute path={ROUTES.CLIENTS} exact={true} isAuthed={isAuthed} component={ClientsContainer} />
                    <Route path={ROUTES._TESTING} exact={true} component={TestingContainer} />

                    <AuthedRoute path={ROUTES.COMPANY} exact={true} isAuthed={isAuthed} component={CompanyContainer} />
                    <AuthedRoute path={ROUTES.ONDEMAND} exact={true} isAuthed={isAuthed} component={OnDemandContainer} />
                    <Route path={ROUTES.FINANCES} exact={true} component={FinancesContainer} />
                    <Route path={ROUTES.SHARE} exact={true} component={ShareContainer} />

                    <Route component={PageNotFoundContainer} />
                </Switch>
            </Suspense>
        );
    }
}
