import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SafeImage from 'components/SafeImage/SafeImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import './AppAlertsCard.scss';

export default class AppAlertsCard extends PureComponent {
    static propTypes = {
        id: PropTypes.number.isRequired,
        icon: PropTypes.string.isRequired,
        icon2: PropTypes.string.isRequired,
        subText: PropTypes.any.isRequired,
        text: PropTypes.any.isRequired,
        type: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        onDismiss: PropTypes.func.isRequired,
        isAutoDismiss: PropTypes.bool.isRequired,
        animationDuration: PropTypes.number.isRequired,
        dismissDuration: PropTypes.number.isRequired,
        renderTimeDisplay: PropTypes.func
    };

    constructor(props) {
        super(props);

        const { dismissDuration, renderTimeDisplay } = props;

        this.dismissCountdown = dismissDuration;
        this.updateInterval = null;
        this.state = {
            dismissTimeoutStartDate: null,
            dismissTimeout: null,
            dismissedTimeout: null,
            timestamp: new Date(),
            timeDisplay: renderTimeDisplay ? renderTimeDisplay() : 'just now'
        };
    }

    componentDidMount() {
        this.startTimeout = setTimeout(this.requestAnimationFrame, 50);
        this.updateInterval = setInterval(this.onUpdateTime, 60000);
    }

    componentWillUnmount() {
        const { startTimeout, updateInterval } = this;
        const { dismissTimeout, dismissedTimeout } = this.state;

        clearTimeout(startTimeout);
        clearTimeout(dismissTimeout);
        clearTimeout(dismissedTimeout);
        clearInterval(updateInterval);
    }

    requestAnimationFrame = () => {
        requestAnimationFrame(this.startDismiss);
    }

    startDismiss = () => {
        const { isAutoDismiss } = this.props;

        this.setState({
            dismissTimeoutStartDate: Date.now(),
            dismissTimeout: isAutoDismiss ? setTimeout(this.onDismiss, this.dismissCountdown) : null
        });
    }

    onMouseEnter = () => {
        const { dismissTimeoutStartDate, dismissTimeout } = this.state;

        clearTimeout(dismissTimeout);

        this.dismissCountdown = this.dismissCountdown - (Date.now() - dismissTimeoutStartDate);
        this.setState({
            dismissTimeout: null
        });
    }

    onMouseLeave = () => {
        this.setState({
            dismissTimeoutStartDate: new Date(),
            dismissTimeout: setTimeout(this.onDismiss, this.dismissCountdown)
        });
    }

    onPin = () => {
        const { dismissTimeout } = this.state;

        clearTimeout(dismissTimeout);

        this.setState({
            dismissTimeout: null,
            isPinned: true
        });
    }

    onUpdateTime = () => {
        const { renderTimeDisplay } = this.props;
        const { timestamp } = this.state;

        this.setState({
            timeDisplay: renderTimeDisplay
                ? renderTimeDisplay(timestamp)
                : formatDistanceStrict(timestamp, new Date(), { addSuffix: true })
        });
    }

    onDismiss = () => {
        const { animationDuration } = this.props;

        this.setState({
            dismissedTimeout: setTimeout(this.onDismissFinal, animationDuration)
        });
    }

    onDismissFinal = () => {
        const { id, onDismiss } = this.props;

        onDismiss(id);
    }

    render() {
        const {
            position, type,
            isAutoDismiss, animationDuration,
            icon, text, subText, icon2
        } = this.props;
        const {
            dismissTimeoutStartDate,
            isPinned, dismissedTimeout

        } = this.state;
        const isMouseHandlers = isAutoDismiss && !isPinned;

        return (
            <div
                className={`AppAlertsCard AppAlertsCard--${type} AppAlertsCard--${position} ${dismissTimeoutStartDate && !dismissedTimeout ? `AppAlertsCard--${position}-init` : ''}`}
                onMouseEnter={isMouseHandlers ? this.onMouseEnter : null}
                onMouseLeave={isMouseHandlers ? this.onMouseLeave : null}
                style={{
                    transitionDuration: `${animationDuration}ms`
                }}>
                {icon && (
                    <div
                        className={`
                            AppAlertsCard__images
                            ${icon2 ? 'AppAlertsCard__images--double' : ''}
                        `}>
                        <SafeImage
                            className='AppAlertsCard__images-item'
                            src={icon}
                            isRound={true}
                            size={icon2 ? 45 : 60} />
                        {icon2 &&
                            <SafeImage
                                className='AppAlertsCard__images-item'
                                src={icon2}
                                isRound={true}
                                size={45} />
                        }
                    </div>
                )}
                <div className='AppAlertsCard__body'>
                    <span className='AppAlertsCard__body-main'>
                        {text}
                    </span>
                    <span className='AppAlertsCard__body-sub'>
                        {subText}
                    </span>
                </div>
                <FontAwesomeIcon
                    className='AppAlertsCard__close'
                    icon={faTimes}
                    onClick={this.onDismiss} />
            </div>
        );
    }
}
