// import i18next from 'i18next';
// import {
//     AUTH_REGISTER_401,
//     AUTH_LOGIN_401
// } from './messages-error';

export function getMessageByResponseError({ status, endpoint }) {
    endpoint = endpoint.split('/');

    switch (endpoint[0]) {
        //         case 'auth': {
        //             switch (endpoint[1]) {
        //                 case 'register': {
        //                     switch (status) {
        //                         case 401: return AUTH_REGISTER_401;
        //                         default: return false;
        //                     }
        //                 }
        //
        //                 case 'login': {
        //                     switch (status) {
        //                         case 401: return AUTH_LOGIN_401;
        //                         default: return false;
        //                     }
        //                 }
        //
        //                 default: return false;
        //             }
        //         }

        default: return false;
    }

    // uncomment to check for "no-unreachable"
    // return false;
}
