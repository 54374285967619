import { format } from 'date-fns';

export const shiftKeys = (obj, shiftKeys) => {
    let retObj = { ...obj };

    for (const [key, value] of Object.entries(shiftKeys)) {
        retObj = { ...retObj, [value]: obj[key] };
    }

    return retObj;
};

export const hasUndefinedValue = (obj, values) => {
    for (const key of values) {
        if (!obj[key] && obj[key] !== false) return true;
    }

    return false;
};

export const formatMoneyComma = (s) => {
    let tmp = `${s}`;

    tmp = tmp.replace(/([0-9]{2})$/g, '.$1');
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');

    return tmp;
};

export const formatMoney = (s) => {
    let numbers = s.match(/\d+/g) ? s.match(/\d+/g) : [];

    numbers = numbers.join('');

    while (numbers[0] === '0') {
        numbers = numbers.slice(1);
    }

    for (let x = 0; x < 3 - numbers.length;) {
        numbers = `0${numbers}`;
    }

    const tmp = `$ ${formatMoneyComma(numbers)}`;

    if (tmp === '0,00') {
        return '';
    } else {
        return tmp;
    }
};

export const formatNumber = (s) => {
    return parseInt(s.replace(/\D*/g, '')) ? parseInt(s.replace(/\D*/g, '')).toString() : '0';
};

export const formatDateTime = (datetime) => {
    return format(new Date(datetime), 'YYY-MM-dd HH:mm:ss');
};

export const formatDate = (date) => {
    return format(new Date(date), 'dd/MM/yyyy');
};

export const formatDateRequest = (date) => {
    return format(new Date(date), 'uuuu-MM-dd');
};

export const formatDay = (date) => {
    return format(new Date(date), 'EEEE, MM/dd');
};

export const formatTime = (datetime) => {
    return format(new Date(datetime), 'HH:mm:ss');
};

export const parseDate = (date) => {
    try {
        const ret = new Date(date);

        ret.setDate(ret.getDate() + 1);

        return ret;
    } catch (e) {
        return null;
    }
};

export const fixUrl = (url) => {
    return url?.includes('http') ? url : `https://${url}`;
};

export const getName = (company_profile) => {
    const { first_name: firstName, last_name: lastName } = company_profile;

    return `${lastName} ${firstName}`;
};

export const removeTime = (date) => {
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

export const getCrossBrowserDate = (date) => {
    if (date instanceof Date) {
        return date;
    } else {
        var a = date.split(/[^0-9]/);

        return new Date(a[0], a[1] - 1 || 0, a[2] || 1, a[3] || 0, a[4] || 0, a[5] || 0, a[6] || 0);
    }
};
