import { lazy } from 'react';

export const LoginContainer = lazy(() => import('containers/Auth/AuthContainer'));
export const SignupContainer = lazy(() => import('containers/Auth/AuthContainer'));
export const CompanyCreationContainer = lazy(() => import('containers/CompanyCreation/CompanyCreationContainer'));
export const CreateProfileContainer = lazy(() => import('containers/CreateProfile/CreateProfileContainer'));
export const DashboardContainer = lazy(() => import('containers/Dashboard/DashboardContainer'));

export const PageNotFoundContainer = lazy(() => import('containers/PageNotFound/PageNotFoundContainer'));

export const TestingContainer = lazy(() => import('containers/_Testing/TestingContainer'));

export const CompanyContainer = lazy(() => import('containers/Company/CompanyContainer'));

export const ClassesContainer = lazy(() => import('containers/Classes/ClassesContainer'));
export const ClientsContainer = lazy(() => import('containers/Clients/ClientsContainer'));
export const OnDemandContainer = lazy(() => import('containers/OnDemand/OnDemandContainer'));

export const FinancesContainer = lazy(() => import('containers/Finances/FinancesContainer'));
export const ShareContainer = lazy(() => import('containers/Share/ShareContainer'));
