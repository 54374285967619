import React, { PureComponent } from 'react';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';
import './AppLoading.scss';

export default class AppLoading extends PureComponent {
    render() {
        return (
            <div className='AppLoading'>
                <ActivityIndicator size={50} />
            </div>
        );
    }
}
