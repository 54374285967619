import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import Button from 'creatella-react-components/lib/Button';
import Avatar from 'images/avatar.svg';
import Input, { THEMES as INPUT_THEMES } from 'components/Input/AppInput';
import { THEMES as BUTTON_THEMES } from 'components/Button/AppButton';
import 'screens/Company/CompanyScreen.scss';

NewClientsModal.propTypes = {
    isNewClientsModalVisible: PropTypes.bool,
    toggleNewClientsModal: PropTypes.func.isRequired,
    onSendClientsInvite: PropTypes.func
};

export default function NewClientsModal({ isNewClientsModalVisible, toggleNewClientsModal, onSendClientsInvite }) {
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [emailClients, setEmailClients] = useState([]);

    const handleEmailChange = (i, value) => {
        const emails = emailClients;

        if (emails[i]) emails[i] = value;
        else emails.push(value);

        setEmailClients([
            ...emails
        ]);
    };

    return (
        <Modal className='Support__modal' open={isNewClientsModalVisible} onClose={toggleNewClientsModal}>
            { selectedMethod === null && (
                <div>
                    <h2 className='Support__modal-title'>Add new clients</h2>
                    <p className='Support__modal-subtitle'>Select how to add new clients</p>
                    <div className='d-flex flex-jsb'>
                        <div className='Support__modal-new-item' onClick={() => setSelectedMethod('contact')}>
                            <img className='Support__modal-new-icon' alt='Social' src={Avatar}/>
                            <div>
                                <p className='Support__modal-new-title'>IMPORT CONTACTS</p>
                                <p className='Support__modal-new-subtitle'>from your other accounts</p>
                            </div>
                        </div>
                        <div className='Support__modal-new-item' onClick={() => setSelectedMethod('email')}>
                            <img className='Support__modal-new-icon' alt='Social' src={Avatar}/>
                            <div>
                                <p className='Support__modal-new-title'>INVITE NEW</p>
                                <p className='Support__modal-new-subtitle'>using 360, phone or email</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-center mt-50'>
                        <Button label='Cancel' className='Social__modal-cancel-btn' onClick={toggleNewClientsModal}/>
                    </div>
                </div>
            )}

            {selectedMethod === 'email' && (
                <div>
                    <div className='InviteEmailModal__content-title'>
                        <span className='InviteEmailModal__content-title-main' >
                            Invite Clients
                        </span>
                        <br />
                        <span className='InviteEmailModal__content-title-sub' >
                            You can skip the empty fields
                        </span>
                    </div>

                    <div className='InviteEmailModal__content-inputs'>
                        {[0, 1, 2, 3].map((i) => (
                            <Input
                                key={i}
                                className='InviteEmailModal__content-inputs-input'
                                theme={INPUT_THEMES.GRAY}
                                value={emailClients[i] ?? ''}
                                onChange={(e) => handleEmailChange(i, e.target.value)}/>
                        ))}
                    </div>

                    <div className='InviteEmailModal__content-buttons'>
                        <Button
                            className='InviteEmailModal__content-buttons-button'
                            theme={BUTTON_THEMES.OUTLINE_GRAY}
                            onClick={() => onSendClientsInvite(emailClients)}>
                            Ok
                        </Button>
                        <Button
                            className='InviteEmailModal__content-buttons-button'
                            theme={BUTTON_THEMES.OUTLINE_GRAY}
                            onClick={() => setSelectedMethod(null)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
}
