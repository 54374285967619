import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import FaqItem from './FaqItem';

export default class Faq extends Component {
    static propTypes = {
        toggleFaq: PropTypes.func.isRequired
    }

    render() {
        const { toggleFaq } = this.props;

        const faqItems = [
            {
                title: 'Item 1',
                questions: [
                    {
                        title: 'Question 1 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 2 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 3 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    }
                ]
            },
            {
                title: 'Item 2',
                questions: [
                    {
                        title: 'Question 1 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 2 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 3 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    }
                ]
            },
            {
                title: 'Item 3',
                questions: [
                    {
                        title: 'Question 1 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 2 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 3 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    }
                ]
            },
            {
                title: 'Item 4',
                questions: [
                    {
                        title: 'Question 1 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 2 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 3 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    }
                ]
            },
            {
                title: 'Item 5',
                questions: [
                    {
                        title: 'Question 1 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 2 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 3 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    }
                ]
            },
            {
                title: 'Item 6',
                questions: [
                    {
                        title: 'Question 1 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 2 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 3 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    }
                ]
            },
            {
                title: 'Item 7',
                questions: [
                    {
                        title: 'Question 1 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 2 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    },
                    {
                        title: 'Question 3 in bold',
                        answer: 'Then the answer if regular and go be multiple lines. IT can also redirect the user, as below.',
                        link: 'Wellness components'
                    }
                ]
            }
        ];

        return (
            <div className='Notifications-Menu pb-70'>
                <p className='Blue-Header__menu-title'>
                    <FontAwesomeIcon className='Blue-Header__menu-back' icon={faChevronLeft} color='#fff'
                        size={'sm'} onClick={toggleFaq} />
                    Frequently asked questions
                </p>
                <div className='Faq__content'>
                    {faqItems.map((item, i) =>
                        <FaqItem
                            key={i}
                            title={item.title}
                            questions={item.questions}/>)}
                </div>
            </div>
        );
    }
}
