import React from 'react';
import { ROUTES, APP_IDS } from 'config/constants';
import COMPANY from 'assets/icons/dashboard-company.svg';
import CLASSES from 'assets/icons/dashboard-classes.svg';
import ON_DEMAND from 'assets/icons/dashboard-ondemand.svg';
import FINANCES from 'assets/icons/dashboard-finances.svg';
import CLIENTS from 'assets/icons/dashboard-clients.svg';
import COMPANY_IMAGE from 'assets/images/dashboard-company.jpg';
import CLASSES_IMAGE from 'assets/images/dashboard-classes.jpg';
import ON_DEMAND_IMAGE from 'assets/images/dashboard-ondemand.jpg';
import FINANCES_IMAGE from 'assets/images/dashboard-finances.jpg';
import CLIENTS_IMAGE from 'assets/images/dashboard-clients.jpg';

const COMPANY_DESCRIPTION = <>
As a new user of 360, this is the first feature you need. Company is where your brand gets an image and where your skills are highlighted for all your future clients to find you on the 360Wellness marketplace. So, what will you decide to promote: Your cool yoga teaching, your self-made nutritionist side or these of a HIIT addict?
    <br />
Alongside your company’s look, what is of crucial importance in terms of branding is the staff members sharing this journey with you. From the feature Company, you will be able to invite new coaches to join your team, create complementary profiles and increase your brand’s impact over the community.
    <br />
Finally, as the company’s owner, you will never loose control on what’s going on: you will be able to optimize the staff members’ description and, if the company’s size increases, to provide them with the admin rights of your choice. These are splitter per feature for an easy use.
    <br />
All this makes Company the starting tool to build your public image. Define it now!
</>;

const CLASSES_DESCRIPTION = <>
Classes are one of your business’ key parts: the more you spend time with your clients, the better it is. Accordingly, Classes provides you with a simple calendar’s step-by-step pop-up to make the scheduling process easy. Schedule a one-to-one class at the gym, continue with a public live event or a private session… There’s no limit.
    <br />
Classes has been conceived to be customizable, either for the activities you deliver (just create new ones) or for the class’ time, duration and prices. Indeed, with 360Wellness, your clients can be all around the world!
    <br />
Even more interesting, every new client joining your event will be automatically added to your clients database - in the feature Clients. This is a perfect boost to increase your clients retention, in complement of the events you will be able to share directly from the app on social medias.
    <br />
Finally, as a staff member, it is relevant to know that your calendar will be shared with other company members for everyone to know what’s going on and potentially optimize the team’s global offer. Clients’ feedbacks after your classes draw in the same perspective, allowing you to collect messages and ratings.
    <br />
In Classes lays your daily commitments! That’s why the feature is conceived to be intuitive and efficient, built by coaches for coaches. Create your first class now!
</>;

const ON_DEMAND_DESCRIPTION = <>
On some periods of your agenda, you may want to let clients book your services instead of scheduling specific events. Indeed, it’s not because there is no classes planned that there is no availability on your hand, right? To make this happen, On-demand gives you the opportunity to let 360’s clients know what your skills are, and when and where they can book your services.
    <br />
Someone needs to be coached about the guidelines of a good sleep hygiene? You are a chief and eager to share your home-made recipes with those interested? Possibilities are limitless, the content is yours and the timing is theirs. Your role is to select the time-window during which you are available (eg, from 8am to 8pm every Monday), and define the purpose and the price. The clients do the rest.
    <br />
This way, On-demand gives back control into the clients’ hands, who become able to find you and book your services according to your specific availabilities. Each time a client will book an available slot of your Calendar (at least 24h in advance and within the next 30 days), you will be notified and see the new event in your Classes.
    <br />
On-demand is then more about one-to-one classes, highlighting similar or different activities from Classes, depending on your offers. This feature has been built to increase your clients’ engagement, always with respect to your available slots.
</>;

const FINANCES_DESCRIPTION = <>
All the money you will collect will be managed into Finances. It is the module where you can track and compare the pay-ins, check forecasts or send payouts to a bank account - yours or one of your staff members.
    <br />
The services and products you will sell via 360 will be organized here. First using the customizable graphic and table, which are complementary. Then via the forecast feature, which is based on the number of clients that already signed-up to your next events. This way, you will be able to anticipate your finances dynamics and regulate your activity.
    <br />
As the company owner, you will also be able to easily track the revenue made by the different team members and trigger new payouts from your net balance. Note that 360Wellness will collect fees only when you decide to withdraw money from the balance (which is already free of previous fees: Apple and Google stores, and Stripe).
    <br />
As an additional option, Finances allows you to send offers for every products you’d like to sell and follow together with your other sources of revenue. Then selling your ebook, your brand’s clothes or any materials to your clients will be easy, centralized and organized.
</>;

const CLIENTS_DESCRIPTION = <>
Clients has several key features. The first one is the way you can find and track new clients. Indeed, you can import and invite new clients from contacts lists you already have elsewhere. You can also find new ones directly on the 360Wellness marketplace, for those you already know. Or you can also decide to create new Classes and attract clients since every one joining your event will be automatically added to your database.
    <br />
Beyond increasing your database, what also matters is your clients to be aware your services. To this purpose, this module enables you to invite them to follow your company. They will be notified of each of your upcoming events, increasing their participation to your classes. More, you will become able to sell them other services and products - via the feature Finances.
    <br />
Finally, as a company owner, each client of your list is private at first. Indeed, it’s up to you to share your clients with your team members, helping them growing their community or letting them building their own one. As every client is special, it is also a way for you to orientate them depending on the coach’s profile and avoid noise in the coach-coachee relationship.
    <br />
These 3 actions make Clients a unique tool for you to combine quantity of clients and quality of offers.
</>;

const createApp = (id, name, tagline, description, icon, image, link, setupLink, multiInstance, dependency) => ({
    id: id,
    name: name,
    icon: icon,
    image: image,
    description,
    tagline,
    link,
    setupLink,
    multiInstance: multiInstance,
    dependency
});

export const APPS = {
    [APP_IDS.COMPANY]: createApp(
        APP_IDS.COMPANY,
        'Company',
        'Your brand, your style',
        COMPANY_DESCRIPTION,
        COMPANY,
        COMPANY_IMAGE,
        ROUTES.COMPANY,
        null,
        false,
        null
    ),
    [APP_IDS.CLASSES]: createApp(
        APP_IDS.CLASSES,
        'Classes',
        'Any activities, anywhere, anytime',
        CLASSES_DESCRIPTION,
        CLASSES,
        CLASSES_IMAGE,
        ROUTES.CLASSES,
        null,
        false,
        null
    ),
    [APP_IDS.ON_DEMAND]: createApp(
        APP_IDS.ON_DEMAND,
        'On-Demand',
        'Your slots, their requests',
        ON_DEMAND_DESCRIPTION,
        ON_DEMAND,
        ON_DEMAND_IMAGE,
        'ondemand',
        null,
        false,
        {
            id: APP_IDS.CLASSES,
            description: 'The app Classes will be required to receive clients booking of new classes.'
        }
    ),
    [APP_IDS.FINANCES]: createApp(
        APP_IDS.FINANCES,
        'Finance',
        'Your finances and payouts',
        FINANCES_DESCRIPTION,
        FINANCES,
        FINANCES_IMAGE,
        ROUTES.FINANCES,
        null,
        false,
        null
    ),
    [APP_IDS.CLIENTS]: createApp(
        APP_IDS.CLIENTS,
        'Clients',
        'The database of your business',
        CLIENTS_DESCRIPTION,
        CLIENTS,
        CLIENTS_IMAGE,
        ROUTES.CLIENTS,
        null,
        false,
        null
    )
};
