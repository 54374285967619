import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import AppRouter from './components/Router/AppRouter';
import AppLoading from './components/Loading/AppLoading';
import HeaderNav from './components/HeaderNav/AppHeaderNavContainer';
import { hideHeaderPublishButton } from 'components/HeaderNav/AppHeaderNav';
import ErrorBoundary from 'creatella-react-components/lib/ErrorBoundary';
import Helmet from 'creatella-react-components/lib/Helmet';
import Alerts from './components/Alerts/AppAlerts';
import { ALERT_POSITION, pushAlert } from 'redux/reducers/alerts';
import { handleAutoLogin } from 'redux/reducers/auth';
import { handleLoadUserCompanies, selectStoreCompany } from 'redux/reducers/company';
import { matchRoute } from 'utils/helpers';
import HELMET_DEFAULT_CONFIG from 'config/executors/i18n-resources/helmet';
import { ROUTES_WITH_HEADER } from 'config/constants';
import './AppContainer.scss';

class AppContainer extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        autoLogin: PropTypes.func.isRequired,
        isProfileReady: PropTypes.bool.isRequired,
        isAuthed: PropTypes.bool.isRequired,
        areCompaniesReady: PropTypes.bool.isRequired,
        loadUserCompanies: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired,
        history: PropTypes.object.isRequired
    }

    componentDidMount() {
        const { autoLogin, loadUserCompanies } = this.props;

        autoLogin();
        loadUserCompanies();
    }

    shouldComponentUpdate(nextProps) {
        const { location, isProfileReady, areCompaniesReady, isAuthed, language } = this.props;
        const { pathname } = location;

        if (pathname !== nextProps.location.pathname ||
            isProfileReady !== nextProps.isProfileReady ||
            areCompaniesReady !== nextProps.areCompaniesReady ||
            isAuthed !== nextProps.isAuthed ||
            language !== nextProps.language) {
            return true;
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname: prevPathname } } = prevProps;
        const { location: { pathname } } = this.props;

        if (prevPathname !== pathname) {
            hideHeaderPublishButton();
        }
    }

    onError = (error, info) => {
        Sentry.withScope((scope) => {
            scope.setExtras('Info', info);
            Sentry.captureException(error);
        });
    }

    render() {
        const {
            isProfileReady, isAuthed,
            areCompaniesReady,
            location,
            language,
            history
        } = this.props;
        const { pathname } = location;

        const header = ROUTES_WITH_HEADER.find(route => matchRoute(pathname, route.path));
        const hasHeadr = !!header;

        const isAppReady = areCompaniesReady && isProfileReady;

        if (isAppReady) {
            return (
                <main
                    className={`
                        AppContainer
                        ${hasHeadr ? 'AppContainer--withHeaderNav' : ''}
                    `}>
                    {hasHeadr && (
                        <HeaderNav
                            isSub={header.isSub}
                            title={header.title}
                            history={history}/>
                    )}
                    <div className='AppContainer__container'>
                        <ErrorBoundary
                            pathname={pathname}
                            onError={this.onError}>
                            <AppRouter
                                isAuthed={isAuthed} />
                        </ErrorBoundary>
                    </div>
                    <Helmet
                        pathname={pathname}
                        defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                    <Alerts
                        position={ALERT_POSITION.TOP_RIGHT} />
                </main>
            );
        }

        return (
            <main className='AppContainer'>
                <Helmet
                    pathname={pathname}
                    defaultConfig={HELMET_DEFAULT_CONFIG[language]} />

                <AppLoading />
            </main>
        );
    }
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    const { isProfileReady, isAuthed } = auth;
    const { areCompaniesReady } = selectStoreCompany(state);
    const { language } = i18n;

    return {
        isProfileReady,
        isAuthed,
        areCompaniesReady,
        language
    };
};

const mapDispatchToProps = {
    autoLogin: handleAutoLogin,
    loadUserCompanies: handleLoadUserCompanies,
    pushAlert
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AppContainer)
);
