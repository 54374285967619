import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAsyncCaller } from 'creatella-react-components/lib/HOCs/withAsyncCaller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import Phone from 'images/phone.svg';
import Email from 'images/email.svg';
import Globe from 'images/globe.svg';
import Pin from 'images/pin.svg';
import USA from 'images/usa-flag.svg';
import Close from 'images/close.svg';
import Button from 'creatella-react-components/lib/Button';
import QuitCompanyModal from './QuitCompanyModal';
import { formatDate } from 'utils/utils';
import { THEMES as INPUT_THEMES } from 'components/Input/AppInput';
import AutoSuggestInput from 'components/AutoSuggestInput/AutoSuggestInput';
import { COUNTRIES } from 'config/countries';
import AvatarUpload from 'images/avatar-upload.svg';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';
import { USER_PERMISSIONS } from 'config/constants';
import AdminModal from 'components/Modals/AdminModal/AdminModal';

class Description extends Component {
    static propTypes = {
        // --withAsyncCaller--
        apiCaller: PropTypes.func.isRequired,

        toggleDescription: PropTypes.func.isRequired,
        company: PropTypes.object,
        account: PropTypes.object,
        onPublish: PropTypes.func,
        onQuitCompany: PropTypes.func,
        onUpdateProfile: PropTypes.func,
        onGrantPermissions: PropTypes.func,
        onUnpublish: PropTypes.func,
        isSubmitLoading: PropTypes.object,
        formSuccess: PropTypes.object,
        formError: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.Ref = React.createRef();
    }

    state = {
        isFetching: true,
        company: {},
        hasJoined: true,
        isModalVisible: false,
        isModalAdminVisible: false,
        formChanged: false,
        form: {
            first_name: '',
            last_name: ''
        },
        country: {
            value: '',
            isSelected: false
        },
        spoken_language: {
            value: '',
            isSelected: false
        },
        permissions: {
            company_description: false,
            company_clients: false,
            company_coaches: false,
            company_client_to_coach: false,
            company_classes: false,
            company_on_demand: false,
            company_finance_create: false,
            company_finance_manage_money: false,
            company_finance_export_data: false
        },
        profile_picture: null,
        profileThumb: null
    }

    componentDidMount() {
        const { account, company } = this.props;

        this.setState({
            form: account
        });

        this.setState({
            permissions: {
                company_description: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_DESCRIPTION) ?? false,
                company_clients: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_CLIENTS) ?? false,
                company_coaches: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_COACHES) ?? false,
                company_client_to_coach: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_CLIENTS_OF_COACH) ?? false,
                company_classes: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_CLASSES) ?? false,
                company_on_demand: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_ON_DEMAND) ?? false,
                company_finance_create: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_FINANCE_CREATE) ?? false,
                company_finance_manage_money: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_FINANCE_MANAGE_MONEY) ?? false,
                company_finance_export_data: company.userPermissions.includes(USER_PERMISSIONS.COMPANY_FINANCE_EXPORT_DATA) ?? false
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            if (this.state.isModalVisible && this.props.formSuccess.quitCompany) {
                this.toggleModal();
                this.props.toggleDescription();
            }
        }
    }

    toggleModal = () => {
        this.setState({
            isModalVisible: !this.state.isModalVisible
        });
    }

    toggleAdminModal = () => {
        this.setState({
            isModalAdminVisible: !this.state.isModalAdminVisible
        });
    }

    onChangeImage = ({ target }) => {
        this.setState({
            formChanged: true
        });

        this.setState({
            profile_picture: target.files[0]
        });

        const reader = new FileReader();

        reader.readAsDataURL(target.files[0]);

        reader.onloadend = () => {
            this.setState({
                profileThumb: reader.result
            });
        };
    };

    handleSuggestionChange = stateProp => selection => {
        this.setState({
            formChanged: true
        });

        this.setState({
            [stateProp]: selection
        });
    };

    handleChangeForm = (name, value) => {
        this.setState({
            formChanged: true
        });

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }

    handlePermissions = (data) => {
        this.setState({
            permissions: data
        });
    }

    handleSubmit = () => {
        const { account, company, onUpdateProfile, onGrantPermissions } = this.props;

        if (!company.isOwner && this.state.permissions !== undefined) {
            const permissions = {};

            permissions.user_id = account.id;

            onGrantPermissions(company.id, permissions);
        }

        const { form, profile_picture } = this.state;
        const profile = form;
        const params = {
            coach_id: profile.coach_id,
            about: profile.about,
            first_name: profile.first_name,
            last_name: profile.last_name,
            email: profile.email,
            gender: profile.gender,
            spoken_language: profile.spoken_language,
            residence: profile.residence,
            timezone: profile.timezone,
            whatsapp: profile.whatsapp || profile.phone_number,
            city: profile.city,
            country: profile.country
        };

        if (profile_picture) params.profile_picture = profile_picture;
        onUpdateProfile(account.id, params);
    }

    handlePublish = () => {
        const { company, onPublish } = this.props;

        onPublish(company.id);
    }

    render() {
        const { toggleDescription, account, company, isSubmitLoading, formError } = this.props;
        const { formChanged, country, profileThumb, permissions, spoken_language } = this.state;
        const countries = COUNTRIES.map((item, index) => ({
            id: index,
            label: item.name,
            ...item
        }));
        const selectedLanguageFlag = COUNTRIES.find(item => item.name === spoken_language.value)?.flag ?? '';

        return (
            <div className='bm-item Notifications-Menu'>
                <p className='Blue-Header__menu-title'>
                    <FontAwesomeIcon className='Blue-Header__menu-back' icon={faChevronLeft} color='#fff'
                        size={'sm'} onClick={toggleDescription} />
                      Description
                </p>
                <div className='Description__content'>
                    <div>
                        <div className='d-flex'>
                            <div style={{ width: '70%' }}>
                                <div className='d-flex'>
                                    <div className='Description__content-avatar'>
                                        {company.logo ? (
                                            <img alt='avatar' src={company?.logo}/>
                                        ) : (
                                            <img alt='avatar' src='https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQlyE29-Zlq79cp9SkIjxYtOXE3xv41XJlweA&usqp=CAU'/>
                                        )}
                                    </div>
                                    <div>
                                        <p className='Description__company-title'>About the company</p>
                                        <p className='Description__company-subtitle'>{company?.name}, since {company?.since} </p>
                                        <p className='Description__company-subtitle'>{company?.about}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <div className='Description__contacts'>
                                    <p className='Description__contacts-title'>Contacts</p>
                                    <div className='d-flex flex-ac'>
                                        <img className='Description__contacts-icon' alt='Phone Icon' src={Phone}/>
                                        <p className='Description__contacts-text'>{company?.phone ?? '-'}</p>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        <img className='Description__contacts-icon' alt='Email Icon' src={Email}/>
                                        <p className='Description__contacts-text'>{company?.email ?? '-'}</p>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        <img className='Description__contacts-icon' alt='Globe Icon' src={Globe}/>
                                        <p className='Description__contacts-text'>{company?.url ?? '-'}</p>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        <img className='Description__contacts-icon' alt='Pin Icon' src={Pin}/>
                                        <p className='Description__contacts-text'>{company?.city ?? '-'}, {company?.country ?? '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex mt-20'>
                            <div style={{ width: '70%' }}>
                                <div className='d-flex mr-20'>
                                    <div className='Description__content-avatar2'>
                                        <img
                                            alt='avatar'
                                            src={profileThumb ?? account?.profile_picture ?? account.photo_url ?? 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQlyE29-Zlq79cp9SkIjxYtOXE3xv41XJlweA&usqp=CAU'}
                                            onClick={(e) => {
                                                this.RefTeam.current.click();
                                                e.preventDefault();
                                            }} />
                                        <img
                                            className='Description__content-avatar2-icon-upload'
                                            alt='Avatar Upload' src={AvatarUpload}
                                            onClick={(e) => {
                                                this.Ref.current.click();
                                                e.preventDefault();
                                            }} />
                                        <input
                                            className='hidden'
                                            alt=''
                                            type='file'
                                            accept='image/*'
                                            ref={this.Ref}
                                            onChange={(e) => this.onChangeImage(e)}/>
                                    </div>
                                    <div className='w-100'>
                                        <p className='Description__user-title'>About me</p>
                                        <div className='d-flex flex-jsb flex-ac'>
                                            <div className='d-flex'>
                                                <input type='text' className='Description__user-text' name='first_name'
                                                    defaultValue={account?.first_name}
                                                    onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                                <input type='text' className='Description__user-text' name='last_name'
                                                    defaultValue={account?.last_name}
                                                    onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                            </div>
                                            {this.state.hasJoined
                                                ? <p className='Description__user-subtext'>Member since: {account?.created_at && formatDate(account?.created_at)}</p> : <p className='Description__user-subtext'>Member since: N/A</p>
                                            }
                                        </div>
                                        <textarea className='Description__user-subtitle w-100' defaultValue={account?.about}
                                            name='about' onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                        {this.state.hasJoined && !company.isOwner &&
                                            <p className='Description__user-admin' onClick={this.toggleAdminModal}>
                                                <FontAwesomeIcon className='mr-10' icon={faCheckSquare} color='#58595B'
                                                    size={'sm'} />
                                                Admin
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <div className='Description__contacts'>
                                    <p className='Description__contacts-title'>Information</p>
                                    <div className='d-flex flex-ac'>
                                        <img className='Description__contacts-icon' alt='Phone Icon' src={Phone}/>
                                        <input type='text' className='Description__contacts-subtext'
                                            placeholder='Phone Number' name='phone_number'
                                            defaultValue={account?.phone_number} onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        <img className='Description__contacts-icon' alt='Email Icon' src={Email}/>
                                        <input type='text' className='Description__contacts-subtext -email'
                                            name='email' placeholder='Email Address' disabled
                                            defaultValue={account?.email} onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        <img className='Description__contacts-icon' alt='Pin Icon' src={Pin}
                                            style={{ marginRight: 15 }}/>
                                        <input type='text' className='Description__contacts-subtext-small mr-10'
                                            placeholder='City' name='city'
                                            defaultValue={account?.city} onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                        <AutoSuggestInput
                                            className='Description__contacts-suggestion-input'
                                            placeholder='Country'
                                            theme={INPUT_THEMES.GRAY}
                                            onChange={this.handleSuggestionChange('country')}
                                            options={countries}
                                            selection={country}/>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        <div className='Description__contacts-icon -invisible'/>
                                        <input type='text' className='Description__contacts-subtext'
                                            placeholder='Office address' name='address'
                                            defaultValue={account?.residence} onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        <div className='Description__contacts-icon -invisible'/>
                                        <input type='text' className='Description__contacts-subtext'
                                            placeholder='Postal code' name='postal'
                                            defaultValue={account?.postal} onChange={(e) => this.handleChangeForm(e.target.name, e.target.value)}/>
                                    </div>
                                    <div className='d-flex flex-ac'>
                                        {selectedLanguageFlag ? (
                                            <img className='Description__contacts-icon' alt='USA Flag' src={selectedLanguageFlag}/>
                                        ) : (
                                            <div className='Description__contacts-icon -invisible'/>
                                        )}
                                        <AutoSuggestInput
                                            className='Description__contacts-subtext -select'
                                            placeholder='Language'
                                            theme={{...INPUT_THEMES.BORDERLESS, ...INPUT_THEMES.TRANSPARENT_GRAY}}
                                            onChange={this.handleSuggestionChange('spoken_language')}
                                            options={countries}
                                            selection={spoken_language}/>
                                        {/*<select className='Description__contacts-subtext -select'*/}
                                        {/*    value={account?.spoken_language ?? this.state.spoken_language ?? ''}*/}
                                        {/*    onChange={(e) => this.handleChangeForm('spoken_language', e.target.value)}>*/}
                                        {/*    <option value='English'>English</option>*/}
                                        {/*    <option value='French'>French</option>*/}
                                        {/*    <option value='Spanish'>Spanish</option>*/}
                                        {/*</select>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='Description__footer'>
                            {this.state.hasJoined
                                ? <>
                                    {!company.isOwner ? (
                                        <span className='Description__footer-quit-btn' onClick={this.toggleModal}>
                                            <img className='Description__footer-quit-icon' alt='Close Icon' src={Close}/>
                                            Quit company
                                        </span>
                                    ) : (
                                        <span/>
                                    )}
                                    <div>
                                        { isSubmitLoading.updateProfile ? (
                                            <ActivityIndicator/>
                                        ) : (
                                            <div>
                                                {/*<Button*/}
                                                {/*    label='Save'*/}
                                                {/*    className={`Description__footer-publish-btn`}*/}
                                                {/*    onClick={() => this.handleSubmit()}/>*/}
                                                <Button
                                                    label='Publish'
                                                    className={`Description__footer-publish-btn ${formChanged && '-blue'}`}
                                                    style={{ marginRight: 0 }} onClick={() => this.handleSubmit()}/>
                                            </div>
                                        )}
                                    </div>
                                </>
                                : <>
                                    <Button label='Decline invitation' className='Description__footer-decline-btn'/>
                                    <Button label='Join the company' className='Description__footer-join-btn'/> </>
                            }
                        </div>
                    </div>
                </div>

                <QuitCompanyModal
                    company={{
                        logo: this.props.company.logo,
                        name: this.props.company.name,
                        coach_id: this.props.company.coach_profile?.coach_id ?? null
                    }}
                    onQuit={this.props.onQuitCompany}
                    isModalVisible={this.state.isModalVisible}
                    toggleModal={this.toggleModal}
                    isSubmitLoading={this.props.isSubmitLoading}
                    formError={formError}/>

                <AdminModal
                    companyName={company.name}
                    data={permissions}
                    isModalAdminVisible={this.state.isModalAdminVisible}
                    toggleAdminModal={this.toggleAdminModal}
                    onSubmitPermissions={(data) => this.handlePermissions(data)}/>
            </div>
        );
    }
}

export default withAsyncCaller(Description);
