import React from 'react';
import PropTypes from 'prop-types';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProfileImage.scss';

ProfileImage.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string
};

export default function ProfileImage({ name, className }) {
    return (
        <div className={`ProfileImage__content ${className}`}>
            { name ? name.charAt(0) : (
                <FontAwesomeIcon icon={faUser}/>
            )}
        </div>
    );
}
