import { getAllCompanies, deleteCompany, updateCompany } from 'api/company';
import { uploadImage } from 'api/upload';
import { replace } from 'connected-react-router';
import { installApp, uninstallApp } from 'api/app';
import { matchRoute } from 'utils/helpers';
import { APP_IDS, USER_PERMISSIONS, ROUTES } from 'config/constants';
import { setStorageSelectedCompany, getStorageSelectedCompany } from './utils/company/helpers';
import { APPS } from 'config/apps';

const APP_ID_BY_API_NAME = {
    company: APP_IDS.COMPANY,
    classes: APP_IDS.CLASSES,
    'on-demand': APP_IDS.ON_DEMAND,
    monetize: APP_IDS.FINANCES,
    clients: APP_IDS.CLIENTS
};

const API_PERMISSIONS_MAP = {
    company_description: USER_PERMISSIONS.COMPANY_DESCRIPTION,
    company_clients: USER_PERMISSIONS.COMPANY_CLIENTS,
    company_coaches: USER_PERMISSIONS.COMPANY_COACHES,
    company_client_to_coach: USER_PERMISSIONS.COMPANY_CLIENTS_OF_COACH,
    company_classes: USER_PERMISSIONS.COMPANY_CLASSES,
    company_on_demand: USER_PERMISSIONS.COMPANY_ON_DEMAND,
    company_finance_create: USER_PERMISSIONS.COMPANY_FINANCE_CREATE,
    company_finance_manage_money: USER_PERMISSIONS.COMPANY_FINANCE_MANAGE_MONEY,
    company_finance_export_data: USER_PERMISSIONS.COMPANY_FINANCE_EXPORT_DATA
};

const FETCH_COMPANIES_START = 'FETCH_COMPANIES_START';
const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
const FETCH_COMPANIES_FAILED = 'FETCH_COMPANIES_FAILED';

const UPLOAD_COMPANY_BANNER_START = 'UPLOAD_COMPANY_BANNER_START';
const UPLOAD_COMPANY_BANNER_SUCCESS = 'UPLOAD_COMPANY_BANNER_SUCCESS';
const UPLOAD_COMPANY_BANNER_FAILED = 'UPLOAD_COMPANY_BANNER_FAILED';

const CHANGE_APPS_START = 'CHANGE_APPS_START';
const CHANGE_APPS_SUCCESS = 'CHANGE_APPS_SUCCESS';
const CHANGE_APPS_FAILED = 'CHANGE_APPS_FAILED';

const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';

const fetchCompaniesStart = () => ({
    type: FETCH_COMPANIES_START
});
const fetchCompaniesSuccess = (companies, selectedId) => ({
    type: FETCH_COMPANIES_SUCCESS,
    companies,
    selectedId
});
const fetchCompaniesFailed = () => ({
    type: FETCH_COMPANIES_FAILED
});

const changeAppsStart = (appId, companyId) => ({
    type: CHANGE_APPS_START,
    appId,
    companyId
});
const changeAppsSuccess = (appId, companyId, isDeleteCompany, isInstall) => ({
    type: CHANGE_APPS_SUCCESS,
    appId,
    companyId,
    isInstall,
    isDeleteCompany
});
const changeAppsFailed = (appId, companyId) => ({
    type: CHANGE_APPS_FAILED,
    appId,
    companyId
});

const uploadCompanyBannerStart = companyId => ({
    type: UPLOAD_COMPANY_BANNER_START,
    companyId
});
const uploadCompanyBannerSuccess = (companyId, banner) => ({
    type: UPLOAD_COMPANY_BANNER_SUCCESS,
    companyId,
    banner
});
const uploadCompanyBannerFailed = companyId => ({
    type: UPLOAD_COMPANY_BANNER_FAILED,
    companyId
});

const setSelectedCompany = id => ({
    type: SET_SELECTED_COMPANY,
    id
});

export function handleLoadUserCompanies() {
    return async (dispatch, getState) => {
        try {
            const {
                router: {
                    location: {
                        pathname
                    }
                }
            } = getState();

            const companies = transformCompanies(
                await getAllCompanies()
            );

            const storedSelectedId = getStorageSelectedCompany();

            dispatch(fetchCompaniesStart());
            const selectedCompanyId = companies.find(company => company.id === storedSelectedId) ? (
                storedSelectedId
            ) : (
                companies[0] ? companies[0].id : null
            );

            dispatch(fetchCompaniesSuccess(companies, selectedCompanyId));
            setStorageSelectedCompany(selectedCompanyId);

            const selectedCompany = companies.find(({ id }) => id === selectedCompanyId);

            const app = Object.keys(APPS).map(key => APPS[key]).find(app => matchRoute(pathname, app.link));

            if (app && !selectedCompany.installedAppIds.includes(app.id)) {
                dispatch(replace(ROUTES.DASHBOARD));
            }
        } catch (err) {
            dispatch(fetchCompaniesFailed());
            console.error(err);
        }
    };
}

export function handleSelectedCompanyChange(id) {
    return async (dispatch) => {
        setStorageSelectedCompany(id);
        dispatch(setSelectedCompany(id));
    };
}

export function handleUninstallApp(appId) {
    return async (dispatch, getState) => {
        const {
            company: {
                selectedCompanyId,
                companies
            }
        } = getState();

        try {
            const selectedCompany = companies.find(({ id }) => id === selectedCompanyId);
            const {
                coach_profile: {
                    id: coachId
                },
                isOwner
            } = selectedCompany;
            const isDeleteCompany = appId === APP_IDS.COMPANY && isOwner;
            const apiParams = isDeleteCompany ? [selectedCompanyId] : [
                selectedCompanyId,
                coachId,
                appId
            ];

            dispatch(changeAppsStart(appId, selectedCompanyId));
            await (isDeleteCompany ? deleteCompany : uninstallApp)(...apiParams);

            dispatch(changeAppsSuccess(appId, selectedCompanyId, isDeleteCompany, false));
        } catch (err) {
            dispatch(changeAppsFailed(appId, selectedCompanyId));
            console.error(err);
        }
    };
}

export function handleInstallApp(appId) {
    return async (dispatch, getState) => {
        const {
            company: {
                selectedCompanyId,
                companies
            }
        } = getState();

        try {
            const coachId = companies.find(({ id }) => id === selectedCompanyId).coach_profile.id;

            dispatch(changeAppsStart(appId, selectedCompanyId));
            await installApp(coachId, appId);

            dispatch(changeAppsSuccess(appId, selectedCompanyId, false, true));
        } catch (err) {
            dispatch(changeAppsFailed(appId, selectedCompanyId));
            console.error(err);
        }
    };
}

export function handleUploadCompanyBanner(file) {
    return async (dispatch, getState) => {
        const {
            company: {
                selectedCompanyId
            }
        } = getState();

        try {
            dispatch(uploadCompanyBannerStart(selectedCompanyId));

            const { data: banner } = await uploadImage(file);

            await updateCompany(selectedCompanyId, { banner: banner });

            dispatch(uploadCompanyBannerSuccess(selectedCompanyId, banner));
        } catch (err) {
            dispatch(uploadCompanyBannerFailed(selectedCompanyId));
            console.error(err);
        }
    };
}

const INITIAL_STATE = {
    areCompaniesReady: false,
    isFetchingCompanies: false,

    companies: [],
    selectedCompanyId: null,

    companyLoad: false,
    oneCompany: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_COMPANIES_START:
            return {
                ...state,
                isFetchingCompanies: true
            };

        case FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                isFetchingCompanies: false,
                areCompaniesReady: true,
                companies: action.companies,
                selectedCompanyId: action.selectedId
            };

        case FETCH_COMPANIES_FAILED:
            return {
                ...state,
                isFetchingCompanies: false,
                areCompaniesReady: true
            };

        case SET_SELECTED_COMPANY:
            return {
                ...state,
                selectedCompanyId: action.id
            };

        case CHANGE_APPS_START: {
            const { companies } = state;
            const { appId, companyId } = action;

            return {
                ...state,
                companies: companies.map(company => company.id === companyId ? ({
                    ...company,
                    loadingAppIds: company.loadingAppIds.includes(appId) ? company.loadingAppIds : [
                        ...company.loadingAppIds,
                        appId
                    ]
                }) : company)
            };
        }

        case CHANGE_APPS_SUCCESS: {
            const { companies } = state;
            const { appId, companyId, isInstall, isDeleteCompany } = action;

            if (!isInstall && isDeleteCompany) {
                const newCompanies = companies.filter(company => company.id !== companyId);

                return {
                    ...state,
                    companies: newCompanies,
                    selectedCompanyId: newCompanies.length ? newCompanies[0].id : null
                };
            }

            return {
                ...state,
                companies: state.companies.map(company => company.id === companyId ? ({
                    ...company,
                    loadingAppIds: company.loadingAppIds.filter(id => id !== appId),
                    installedAppIds: isInstall ? ([
                        ...company.installedAppIds,
                        appId
                    ]) : (
                        company.installedAppIds.filter(id => id !== appId)
                    )
                }) : company)
            };
        }

        case CHANGE_APPS_FAILED: {
            const { companies } = state;
            const { appId, companyId } = action;

            return {
                ...state,
                companies: companies.map(company => company.id === companyId ? ({
                    ...company,
                    loadingAppIds: company.loadingAppIds.filter(id => id !== appId)
                }) : company)
            };
        }

        // --UPLOAD COMPANY BANNER--
        case UPLOAD_COMPANY_BANNER_START: {
            const { companies } = state;
            const { companyId } = action;

            return {
                ...state,
                companies: companies.map(company => company.id === companyId ? ({
                    ...company,
                    isUploadingBanner: true
                }) : company)
            };
        }

        case UPLOAD_COMPANY_BANNER_SUCCESS: {
            const { companies } = state;
            const { companyId, banner } = action;

            return {
                ...state,
                companies: companies.map(company => company.id === companyId ? ({
                    ...company,
                    isUploadingBanner: false,
                    banner
                }) : company)
            };
        }

        case UPLOAD_COMPANY_BANNER_FAILED: {
            const { companies } = state;
            const { companyId } = action;

            return {
                ...state,
                companies: companies.map(company => company.id === companyId ? ({
                    ...company,
                    isUploadingBanner: false
                }) : company)
            };
        }

        default:
            return state;
    }
};

function transformCompanies(response) {
    const { data: { data: companies } } = response;

    return companies.filter(company => !!company.coach_profile).map(company => {
        const isOwner = company.is_owner;
        const coachProfile = company.coach_profile;

        const userPermissions = isOwner ? (
            Object.keys(USER_PERMISSIONS).map(key => USER_PERMISSIONS[key])
        ) : (
            (coachProfile ? coachProfile.permissions : []).map(permission => API_PERMISSIONS_MAP[permission])
        );

        const installedAppIds = (company.coach_profile.apps || []).map(app => APP_ID_BY_API_NAME[app.name]);

        if (isOwner) {
            installedAppIds.push(APP_IDS.COMPANY);
        }

        return {
            id: company.id,
            name: company.name,
            since: company.since,
            about: company.about,
            city: company.company_city,
            country: company.company_country,
            logo: company.logo,
            banner: company.banner,
            installedAppIds,
            loadingAppIds: [],
            userPermissions,
            isOwner,
            isUploadingBanner: false,
            owners: company.owners,
            coach_profile: {
                id: coachProfile.id,
                about: coachProfile.about,
                email: coachProfile.email,
                coach_id: coachProfile.id,
                photo_url: coachProfile.photo_url,
                image: coachProfile.photo_url || coachProfile.profile_picture || coachProfile.photo_url,
                user_id: coachProfile.user_id,
                first_name: coachProfile.first_name,
                last_name: coachProfile.last_name,
                name: `${coachProfile.last_name} ${coachProfile.first_name}`,
                city: coachProfile.city,
                country: coachProfile.country
            }
        };
    });
}

export const selectStoreCompany = state => {
    const { company } = state;
    const {
        areCompaniesReady,
        isFetchingCompanies,

        companies,
        selectedCompanyId
    } = company;

    return {
        areCompaniesReady,
        isFetchingCompanies,

        companies,
        selectedCompanyId
    };
};
