import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import './Modal.scss';

export default class Modal extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        containerClassName: PropTypes.string,
        onClose: PropTypes.func,
        isOpen: PropTypes.bool.isRequired,
        closeOnOverlayClick: PropTypes.bool,
        customSize: PropTypes.bool
    }

    static defaultProps = {
        className: '',
        containerClassName: ''
    }

    render() {
        const { className, containerClassName, onClose, isOpen, customSize, closeOnOverlayClick, ...others } = this.props;

        return (
            <ReactModal
                {...others}
                className={`
                    Modal__content
                    ${customSize ? 'Modal__content--customSize' : ''}
                    ${className}
                `}
                overlayClassName={`
                    Modal
                    ${containerClassName}
                `}
                isOpen={isOpen}
                onRequestClose={onClose}
                shouldCloseOnOverlayClick={closeOnOverlayClick ?? true}
                shouldCloseOnEsc={true} />
        );
    }
}
