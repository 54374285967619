import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Question extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired,
        link: PropTypes.string
    }

    render() {
        return (
            <div>
                <p className='Faq__item-dropdown-text'>{this.props.title}</p>
                <p className='Faq__item-dropdown-subtext'>{this.props.answer}</p>
                <p className='Faq__item-dropdown-subtext'>Find it in: <a href='/'>{this.props.link} </a></p>
            </div>
        );
    }
}
