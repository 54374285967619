import React from 'react';
import PropTypes from 'prop-types';
import Button from 'creatella-react-components/lib/Button';
import { Modal } from 'react-responsive-modal';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';

QuitCompanyModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    company: PropTypes.object,
    onQuit: PropTypes.func,
    isSubmitLoading: PropTypes.object,
    formError: PropTypes.object
};

export default function QuitCompanyModal({ isModalVisible, toggleModal, onQuit, company, isSubmitLoading, formError }) {
    return (
        <Modal className='Support__modal' open={isModalVisible} onClose={toggleModal}>
            <h2 className='Support__modal-title'>Quit company</h2>
            <p className='Support__modal-subtitle'>
                Are you sure you want to leave the company?<br/>
                The company will be uninstalled from your Homepage.
            </p>
            <div className='Support__modal-delete'>
                {company.logo ? (
                    <img className='Support__modal-avatar-image' alt='Avatar' src={company?.logo}/>
                ) : (
                    <img className='Support__modal-avatar-image'
                         alt='avatar' src='https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQlyE29-Zlq79cp9SkIjxYtOXE3xv41XJlweA&usqp=CAU'/>
                )}
                {/*<img className='Support__modal-avatar-image' alt='Avatar' src={company?.logo}/>*/}
                <p className='Support__modal-avatar-title'>{company?.name ?? ''}</p>
            </div>
            <div className='d-flex flex-jc'>
                { isSubmitLoading.quitCompany ? (
                    <ActivityIndicator size={50} />
                ) : (
                    <div className='w-100 d-flex flex-jsb mt-50'>
                        <Button label='Cancel' className='Social__modal-cancel-btn' onClick={toggleModal}/>
                        <Button label='Quit' className='Social__modal-continue-btn' onClick={() => {
                            onQuit(company.coach_id);
                        }}/>
                    </div>
                )}
            </div>
            {formError?.quitCompany && (
                <p style={{ color: '#E63939', textAlign: 'center' }}>Something Wrong!</p>
            )}
        </Modal>
    );
}
