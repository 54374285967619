import { createClass, deleteOneClass, fetchOneClass, publishClass, unpublishClass, fetchClasses, updateClass } from '../../api/class';

const CREATE_CLASS = 'CREATE_CLASS';
const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS';
const CREATE_CLASS_FAILURE = 'CREATE_CLASS_FAILURE';

const FETCH_ALL_CLASSES = 'FETCH_ALL_CLASSES';
const FETCH_ALL_CLASSES_SUCCESS = 'FETCH_ALL_CLASSES_SUCCESS';
const FETCH_ALL_CLASSES_FAILURE = 'FETCH_ALL_CLASSES_FAILURE';

const FETCH_ONE_CLASS = 'FETCH_ONE_CLASS';
const FETCH_ONE_CLASS_SUCCESS = 'FETCH_ONE_CLASS_SUCCESS';
const FETCH_ONE_CLASS_FAILURE = 'FETCH_ONE_CLASS_FAILURE';

const DELETE_CLASS = 'DELETE_CLASS';
const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
const DELETE_CLASS_FAILURE = 'DELETE_CLASS_FAILURE';

const PUBLISH_CLASS = 'PUBLISH_CLASS';
const PUBLISH_CLASS_SUCCESS = 'PUBLISH_CLASS_SUCCESS';
const PUBLISH_CLASS_FAILURE = 'PUBLISH_CLASS_FAILURE';

const UPDATE_CLASS = 'UPDATE_CLASS';
const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS';
const UPDATE_CLASS_FAILURE = 'UPDATE_CLASS_FAILURE';

const addClass = (data) => ({
    type: CREATE_CLASS,
    data
});

const createClassSuccess = (coaches) => ({
    type: CREATE_CLASS_SUCCESS,
    coaches
});

const createClassFailure = () => ({
    type: CREATE_CLASS_FAILURE
});

export function handleCreateClass(id, data) {
    return async (dispatch) => {
        try {
            dispatch(addClass(data));

            const classes = await createClass(id, data);

            dispatch(createClassSuccess(classes.data.data));
        } catch (e) {
            dispatch(createClassFailure());
            console.error(e);
        }
    };
}

const startFetchAllClasses = () => ({
    type: FETCH_ALL_CLASSES
});

const fetchAllClassesSuccess = (classes) => ({
    type: FETCH_ALL_CLASSES_SUCCESS,
    classes
});

const fetchAllClassesFailure = () => ({
    type: FETCH_ALL_CLASSES_FAILURE
});

export function handleFetchAllClasses(id) {
    return async (dispatch) => {
        try {
            dispatch(startFetchAllClasses());

            const { data: result } = await fetchClasses(id);

            dispatch(fetchAllClassesSuccess(result.data));
        } catch (e) {
            dispatch(fetchAllClassesFailure());
            console.error(e);
        }
    };
}

const fetchClass = () => ({
    type: FETCH_ONE_CLASS
});

const fetchClassSuccess = (item) => ({
    type: FETCH_ONE_CLASS_SUCCESS,
    item
});

const fetchClassFailure = () => ({
    type: FETCH_ONE_CLASS_FAILURE
});

export function handleFetchClass(id) {
    return async (dispatch) => {
        try {
            dispatch(fetchClass());

            const result = await fetchOneClass(id);

            dispatch(fetchClassSuccess(result.data.data));
        } catch (e) {
            dispatch(fetchClassFailure());
            console.error(e);
        }
    };
}

const deleteClass = () => ({
    type: DELETE_CLASS
});

const deleteClassSuccess = (res) => ({
    type: DELETE_CLASS_SUCCESS,
    res
});

const deleteClassFailure = () => ({
    type: DELETE_CLASS_FAILURE
});

export function handleDeleteClass(id) {
    return async (dispatch) => {
        try {
            dispatch(deleteClass());

            const result = await deleteOneClass(id);

            dispatch(deleteClassSuccess(result.data.data));
        } catch (e) {
            dispatch(deleteClassFailure());
            console.error(e);
        }
    };
}

const publishOneClass = () => ({
    type: PUBLISH_CLASS
});

const publishOneClassSuccess = (res) => ({
    type: PUBLISH_CLASS_SUCCESS,
    res
});

const publishOneClassFailure = () => ({
    type: PUBLISH_CLASS_FAILURE
});

export function handlePublishClass(id) {
    return async (dispatch) => {
        try {
            dispatch(publishOneClass());

            const result = await publishClass(id);

            dispatch(publishOneClassSuccess(result.data.data));
        } catch (e) {
            dispatch(publishOneClassFailure());
            console.error(e);
        }
    };
}

const unpublishOneClass = () => ({
    type: PUBLISH_CLASS
});

const unpublishOneClassSuccess = (res) => ({
    type: PUBLISH_CLASS_SUCCESS,
    res
});

const unpublishOneClassFailure = () => ({
    type: PUBLISH_CLASS_FAILURE
});

export function handleUnpublishClass(id) {
    return async (dispatch) => {
        try {
            dispatch(unpublishOneClass());

            const result = await unpublishClass(id);

            dispatch(unpublishOneClassSuccess(result.data.data));
        } catch (e) {
            dispatch(unpublishOneClassFailure());
            console.error(e);
        }
    };
}

const updateOneClass = () => ({
    type: UPDATE_CLASS
});

const updateOneClassSuccess = (res) => ({
    type: UPDATE_CLASS_SUCCESS,
    res
});

const updateOneClassFailure = () => ({
    type: UPDATE_CLASS_FAILURE
});

export function handleUpdateClass(id, data) {
    return async (dispatch) => {
        try {
            dispatch(updateOneClass());

            const result = await updateClass(id, data);

            dispatch(updateOneClassSuccess(result.data));
        } catch (e) {
            dispatch(updateOneClassFailure());
            console.error(e);
        }
    };
}

const INITIAL_STATE = {
    classesLoad: false,
    classes: [],
    classLoad: false,
    oneClass: {},
    create: {},
    deleteLoad: false,
    deleteSuccess: false,
    deleteError: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ONE_CLASS:
            return {
                classLoad: true
            };
        case FETCH_ONE_CLASS_SUCCESS:
            return {
                classLoad: false,
                oneClass: action.class
            };
        case FETCH_ONE_CLASS_FAILURE:
            return {
                classLoad: false,
                error: true
            };
        case FETCH_ALL_CLASSES:
            return {
                allClassesLoad: true
            };
        case FETCH_ALL_CLASSES_SUCCESS:
            return {
                allClassesLoad: false,
                allClasses: action.classes
            };
        case FETCH_ALL_CLASSES_FAILURE:
            return {
                allClassesLoad: false,
                error: true
            };
        case UPDATE_CLASS:
            return {
                updateClassLoad: true
            };
        case UPDATE_CLASS_SUCCESS:
            return {
                updateClassLoad: false,
                allClass: {
                    ...state,
                    allClass: state.allClasses.map(el => el.id === action.res.id ? { ...action.res } : { ...el })
                }
            };
        case UPDATE_CLASS_FAILURE:
            return {
                updateClassesLoad: false,
                error: true
            };
        case DELETE_CLASS_SUCCESS:
            return {
                deleteLoad: false,
                deleteSuccess: true
            };
        default:
            return state;
    }
};
