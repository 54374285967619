import { ELOQUENT, APP_IDS } from 'config/constants';

const NAME_BY_IDE = {
    [APP_IDS.COMPANY]: 'company',
    [APP_IDS.CLASSES]: 'classes',
    [APP_IDS.ON_DEMAND]: 'on-demand',
    [APP_IDS.FINANCES]: 'monetize',
    [APP_IDS.CLIENTS]: 'clients'
};

/**
 * install app
 * @param {String} coachId
 * @param {String} appId
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function installApp(coachId, appId, cancelKey) {
    return ELOQUENT.InstalledApp.createOne({
        coach_id: coachId,
        app_name: NAME_BY_IDE[appId]
    }, cancelKey);
}

/**
 * uninstall app
 * @param {String} companyId
 * @param {String} coachId
 * @param {String} appId
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function uninstallApp(companyId, coachId, appId, cancelKey) {
    return ELOQUENT.InstalledApp.uninstall({
        company_id: companyId,
        coach_id: coachId,
        app_name: NAME_BY_IDE[appId]
    }, cancelKey);
}

/**
 * uninstall app
 * @param {String} companyId
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getApps(companyId, cancelKey) {
    return ELOQUENT.InstalledApp.getCompanyApps(companyId, cancelKey);
}
