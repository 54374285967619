import { ELOQUENT } from 'config/constants';

/**
 * process invitation
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function processInvitation({
    provider,
    providerToken,
    inviteToken,
    nationality,
    city,
    country,
    introuction,
    photo,
    clients
}, cancelKey) {
    return ELOQUENT.Invite.process({
        provider: provider,
        provider_token: providerToken,
        invite_token: inviteToken,
        about: introuction,
        city: city,
        country: country,
        nationality: nationality,
        photo: photo,
        client: clients ? [
            {
                whatsapp: clients.phone
            },
            {
                email: clients.email
            },
            {
                id: clients.id
            }
        ] : undefined
    }, cancelKey);
}

/**
 * send invitation
 * @param {Object} data
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function sendInvitation(data, cancelKey) {
    return ELOQUENT.Invite.send(data, cancelKey);
}

/**
 * get OAuth url
 * @param {String} provider
 * @param {String} inviteToken
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function getInvitationAuthUrl(provider, inviteToken, cancelKey) {
    return ELOQUENT.Invite.getRedirect({
        provider: provider,
        invite_token: inviteToken
    });
}
