import { STORAGEKEY_SELECTED_COMPANY_ID_KEY } from 'config/constants';

/**
 * Save login token
 * @param {String} id
 * @param {Boolean} isRemember
 */
export function setStorageSelectedCompany(id) {
    localStorage.setItem(STORAGEKEY_SELECTED_COMPANY_ID_KEY, id);
}

/**
 * Get login token
 * @returns {String}
 */
export function getStorageSelectedCompany() {
    return localStorage.getItem(STORAGEKEY_SELECTED_COMPANY_ID_KEY);
}
