import {
    parseUrl as qsParseUrl,
    stringifyUrl as qsStringifyUrl
} from 'query-string';
import { ROUTE_MATCH_REPLACER } from 'config/constants';

/**
 * check if a pathname matches an app route
 * @param  {String} pathname
 * @param  {String} route
 * @returns {Promise}
 */
export function matchRoute(pathname, route) {
    const routeMatchReplacerIndex = route.indexOf(ROUTE_MATCH_REPLACER);

    if (routeMatchReplacerIndex > -1) {
        const routeChunk = route.slice(0, routeMatchReplacerIndex);
        const pathChunk = pathname.slice(routeMatchReplacerIndex);

        return equalPath(`${routeChunk}${pathChunk}`, pathname);
    }

    return equalPath(route, pathname);
}

/**
 * check if two paths are equal
 * @param  {String} path1
 * @param  {String} path2
 * @returns {Promise}
 */
export function equalPath(path1, path2) {
    return path1.replace(/^\/|\/$/g, '') === path2.replace(/^\/|\/$/g, '');
}

/**
 * pares url
 * @param  {String} url
 * @returns {Promise}
 */
export const parseUrl = url => qsParseUrl(url, { arrayFormat: 'comma', parseBooleans: true });

/**
 * stringify url
 * @param  {String} baseUrl
 * @param  {Object} queryParams
 * @returns {Promise}
 */
export const stringifyUrl = (baseUrl, queryParams = {}) =>
    qsStringifyUrl({
        url: baseUrl,
        query: queryParams
    }, { arrayFormat: 'comma' });

/**
 * stringify url
 * @param  {Array} array1
 * @param  {Array} array2
 * @returns {Promise}
 */
export const arrayEquals = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false;
    }

    return array1.every(item => array2.includes(item));
};

/**
 * Fix Floating Point Number Precision
 * @param {Number} num
 * @param {Number} precision
 * @param {Boolean} addZero
 * @returns {String}
 */
export function fixFloating(num, precision = 2, addZero = false) {
    let str = num.toString();

    let parts = str.split('.');

    let decimals = parts[1];

    if (!decimals) {
        decimals = '';
    }

    decimals = `${decimals.substr(0, precision + 1)}`;

    if (decimals.length > precision && parseInt(parts[1][precision]) > 5) {
        str = (parseFloat(`${parts[0]}.${decimals}`) + 1 / (10 ** precision)).toString();

        parts = str.split('.');

        decimals = parts[1];

        if (!decimals) {
            decimals = '';
        }
    }

    decimals = decimals.substr(0, precision);

    if (addZero) {
        decimals = fillZero(decimals, 2, 'after');
    }

    return `${parts[0]}${(decimals !== '' && (parseInt(decimals) || addZero)) ? ('.' + decimals) : ''}`;
}

/**
 * if num length less than count, fill with zero
 * @param  {Number} num
 * @param  {Number} count
 * @param  {String} where
 * @returns {String}
 */
export function fillZero(num, count = 2, where) {
    const stringifiedNum = num.toString();
    const diff = count - stringifiedNum.length;

    if (diff > 0) {
        if (where === 'after') {
            return `${stringifiedNum}${'0'.repeat(diff)}`;
        } else {
            return `${'0'.repeat(diff)}${stringifiedNum}`;
        }
    }

    return stringifiedNum;
}

/**
 * Proxy all currency values (attach currency symbol, etc)
 * @param {Number} price
 * @param {Boolean} signed
 * @param {Boolean} addZero
 * @param {Boolean} symbolAfter
 * @param {String} symbol
 * @returns {String}
 */
export function currencify(price, signed, addZero, symbolAfter, symbol = '$') {
    const addSymbol = (str, symbol) => symbolAfter ? `${str}${symbol}` : `${symbol}${str}`;

    if (price === 0) {
        return addSymbol(fixFloating(0, 2, addZero), symbol);
    }

    if (price > 0) {
        return !signed ? (
            addSymbol(fixFloating(price, 2, addZero), symbol)
        ) : (
            `+${addSymbol(fixFloating(price, 2, addZero), symbol)}`
        );
    }

    if (price < 0) {
        return `-${addSymbol(fixFloating(Math.abs(price), 2, addZero), symbol)}`;
    }
}

export { isSafari } from 'react-device-detect';
