import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';
import './AppButton.scss';

export const THEMES = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    DARK_BLUE: 'dark-blue',
    LAVENDER: 'lavender',
    LAVENDER_BLUE: 'lavender-blue',
    OUTLINE_WHITE: 'outline-white',
    OUTLINE_GRAY: 'outline-gray',
    OUTLINE_LIGHT_GRAY: 'outline-light-gray',
    OUTLINE_BLUE: 'outline-blue',
    OUTLINE_DARK_BLUE: 'outline-dark-blue',
    OUTLINE_BLUE_GRAY: 'outline-blue-gray',
    SOCIAL_GOOGLE: 'social-google',
    SOCIAL_FACEBOOK: 'social-facebook',
    ALICE_GRAY: 'alice-gray',
    ALICE_BLUE: 'alice-blue',
    OUTLINE_ELECTRIC_BLUE: 'outline-electric-blue'
};

const AppButtonWithoutRef = props => {
    const {
        className = '',
        style,
        buttonClassName,
        containerProps = {},
        theme = THEMES.PRIMARY,
        onClick,
        noActive,
        noBorder,
        noPadding,
        noHover,
        hover,
        to,
        component,
        loading,
        disabled,
        children,
        noShrink,
        ignoreClick,
        selected,
        innerRef,
        round,
        ...others
    } = props;

    const Component = to ? Link : (component || 'button');

    return (
        <span
            {...containerProps}
            className={`
                AppButton
                AppButton--${theme}
                ${ignoreClick ? 'AppButton--ignoreClick' : ''}
                ${(!noActive && !disabled && !ignoreClick) ? 'AppButton--active' : ''}
                ${!noBorder ? 'AppButton--border' : ''}
                ${(!noHover && !disabled && !ignoreClick) ? 'AppButton--hover' : ''}
                ${loading === true ? 'AppButton--loading' : ''}
                ${loading === false ? 'AppButton--not-loading' : ''}
                ${hover ? 'AppButton--hovered' : ''}
                ${disabled ? 'AppButton--disabled' : ''}
                ${noShrink ? 'AppButton--no-shrink' : ''}
                ${round ? 'AppButton--round' : ''}
                ${selected ? 'AppButton--selected' : ''}
                ${noPadding ? 'AppButton--noPadding' : ''}
                ${className}
            `}
            style={style} >
            <div className={cn('AppButton__placeholder', buttonClassName)} >
                {children}
            </div>
            <Component
                {...others}
                ref={innerRef}
                className={cn(
                    'AppButton__button',
                    buttonClassName
                )}
                to={to}
                disabled={disabled}
                onClick={(ignoreClick || loading) ? undefined : onClick}>
                {children && (
                    <>
                        <span className='AppButton__button-content'>
                            {children}
                        </span>
                        {loading && <span className='AppButton__button-loading'>
                            <ActivityIndicator
                                className='AppButton__button-loading-spinner'
                                size={20} />
                        </span>}
                    </>
                )}
            </Component>
        </span>
    );
};

AppButtonWithoutRef.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
    buttonClassName: PropTypes.string,
    theme: PropTypes.oneOf(Object.keys(THEMES).map(key => THEMES[key])),
    to: PropTypes.string,
    onClick: PropTypes.func,
    containerProps: PropTypes.object,
    disabled: PropTypes.bool,
    noActive: PropTypes.bool,
    noBorder: PropTypes.bool,
    noHover: PropTypes.bool,
    hover: PropTypes.bool,
    loading: PropTypes.bool,
    component: PropTypes.elementType,
    noShrink: PropTypes.bool,
    ignoreClick: PropTypes.bool,
    selected: PropTypes.bool,
    noPadding: PropTypes.bool,
    innerRef: PropTypes.object
};

const AppButton = React.forwardRef((props, ref) => (
    <AppButtonWithoutRef
        innerRef={ref}
        {...props}/>
));

AppButton.propTypes = AppButtonWithoutRef.propTypes;

export default AppButton;
