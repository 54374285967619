import { ELOQUENT, AXIOS, API_URL } from 'config/constants';

// const store = async (file, options = {}) => {
//     const response = await AXIOS.post(`${API_URL}vapor/signed-storage-url`, {
//         bucket: options.bucket || '',
//         content_type: options.contentType || file.type,
//         expires: options.expires || '',
//         visibility: options.visibility || ''
//     }, {
//         baseURL: options.baseURL || null,
//         headers: options.headers || {},
//         ...options.options
//     });
//
//     const headers = response.data.headers;
//
//     if ('Host' in headers) {
//         delete headers.Host;
//     }
//
//     if (typeof options.progress === 'undefined') {
//         options.progress = () => {};
//     }
//
//     const cancelToken = options.cancelToken || '';
//
//     await AXIOS.put(response.data.url, file, {
//         cancelToken: cancelToken,
//         headers: headers,
//         onUploadProgress: (progressEvent) => {
//             options.progress(progressEvent.loaded / progressEvent.total);
//         }
//     });
//
//     response.data.extension = file.name.split('.').pop();
//
//     return response.data;
// };

/**
 * upload image
 * @param {Object} file
 * @param {Object} cancelKey
 * @returns {Promise}
 */
export function uploadImage(file, cancelKey) {
    // try {
    //     store(file, {
    //         progress: console.info(),
    //         cancelToken: cancelKey
    //     }).then(response => console.info(response));
    // } catch (err) {
    //     console.error(err);
    // }

    return ELOQUENT.Upload.file(file, cancelKey);
}
