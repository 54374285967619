import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {startOfToday, differenceInDays, format, formatDistance, subDays} from "date-fns";

export default class NotificationItem extends Component {
    static propTypes = {
        image: PropTypes.string,
        time: PropTypes.string.isRequired,
        isNew: PropTypes.bool,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        action: PropTypes.string,
        info: PropTypes.string,
        notification: PropTypes.string.isRequired,
        onReadNotification: PropTypes.func
    }

    state = {
        showDelete: false
    }

    setIsShown = (val) => {
        this.setState({
            showDelete: val
        });
    }

    handleClick = () => {

    }

    generateTime = () => {
        var today = startOfToday();
        const date = new Date(this.props.time);
        var diff = differenceInDays(today, date);

        if (diff > 6) {
            return format(date, 'MMM dd, yyyy hh:mm')
        } else if (diff > 0) {
            return formatDistance(date, new Date(), { addSuffix: true }) + ' at ' + format(date, 'hh:mm');
        } else {
            return formatDistance(date, new Date(), { addSuffix: true });
        }
    }

    render() {
        const { showDelete } = this.state;
        let { image, title, subtitle, time, isNew, info, notification, onReadNotification } = this.props;

        return (
            <div className='Notifications__item' onMouseEnter={() => this.setIsShown(true)}
                onMouseLeave={() => this.setIsShown(false)} onClick={this.handleClick}>
                <div className='Notifications__item-header'>
                    <div className='d-flex flex-ac'>

                        <div className='Notifications__item-user'>
                            {image ? (
                                <img src={image} alt='user' />
                            ) : (
                                <img src='https://cdn1.vectorstock.com/i/1000x1000/02/10/business-woman-avatar-vector-15490210.jpg' alt='user' />
                            )}
                        </div>

                        <p className='Notifications__item-title'>{title}</p>
                    </div>

                    <div className='d-flex flex-ac'>
                        {showDelete
                            ? <FontAwesomeIcon icon={faTimesCircle} color='#58595B' size={'md'}
                                onClick={onReadNotification} />
                            : <p className='Notifications__item-hr'>{this.generateTime()}
                                {isNew && <span className='Notifications__item-dot'/>}
                            </p>}
                    </div>
                </div>
                <div className='Notifications__item-content'>
                    <p className='Notifications__item-info'>{subtitle}</p>
                    <p className='Notifications__item-info-msg'>{info}</p>
                    <p className='Notifications__item-info'>{notification}</p>
                </div>
            </div>
        );
    }
}
