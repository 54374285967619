const SET_SHOW_PUBLISH = 'SET_SHOW_PUBLISH';

export function setShowPublish(value) {
    return {
        type: SET_SHOW_PUBLISH,
        value
    };
}

const initialState = {
    showPublish: false
};

export default function alerts(state = initialState, action) {
    switch (action.type) {
        case SET_SHOW_PUBLISH:
            return {
                ...state,
                showPublish: action.value
            };

        default:
            return state;
    }
}
