import React, { Component } from 'react';
import Toggle from 'react-toggle';
import PropTypes from 'prop-types';

export default class Preference extends Component {
    static propTypes = {
        title: PropTypes.string,
        name: PropTypes.string,
        checked: PropTypes.bool,
        onChange: PropTypes.func
    };

    render() {
        const { title, name, checked, onChange } = this.props;

        return (
            <div className='Preferences__preference'>
                <p className='mb-0 mt-0'>{title}</p>
                <div className='Preferences__toggle d-flex'>
                    <Toggle
                        defaultChecked={checked}
                        checked={checked}
                        icons={false}
                        onChange={() => onChange(name, title, !checked)} />
                </div>
            </div>
        );
    }
}
