import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import Button from 'creatella-react-components/lib/Button';

LinkAccountModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    socialType: PropTypes.string
};

export default function LinkAccountModal({ isModalVisible, toggleModal, socialType }) {
    return (
        <Modal className='Social__modal' open={isModalVisible} onClose={toggleModal}>
            <h2 className='Social__modal-title'>Link your {socialType} account</h2>
            <p className='Social__modal-subtitle'>You will be redirected to {socialType}</p>
            <div className='d-flex flex-jsb'>
                <Button label='Cancel' className='Social__modal-cancel-btn' onClick={toggleModal}/>
                <Button label='Continue' className='Social__modal-continue-btn' onClick={toggleModal}/>
            </div>
        </Modal>
    );
}
