/***
 *
 * NOTE: This is ONLY for <Helmet /> in `src/containers/App/AppContainer`
 *
 */
import { getAppLanguage } from 'redux/reducers/utils/i18n/helpers';
import { FACEBOOK_APP_ID } from 'config/constants';

const STATIC_CONFIG = {
    image: 'TODO_REPLACE(optimal: 1200px x 630px (max 5mb, ratio: 1.91:1))',
    siteName: '360Sports',
    type: 'website',
    language: getAppLanguage(),
    twitterSite: 'TODO_REPLACE(@twitter_username see: https://developer.twitter.com/en/docs/tweets/optimize-with-cards/guides/getting-started)',
    fbAppId: FACEBOOK_APP_ID,
    children: null
};

// HELMET - see https://github.com/SchwSimon/creatella-react-components#helmet-
export default {
    en: {
        ...STATIC_CONFIG,
        description: 'TODO_REPLACE(50 – 160 characters)',
        title: '360Sports'
    }
    // de: { ... }
    // fr: { ... }
    // ...
};
