import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-responsive-modal';
import Button from 'creatella-react-components/lib/Button';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import ActivityIndicator from 'creatella-react-components/lib/ActivityIndicator';

DeleteAccountModal.propTypes = {
    account: PropTypes.object,
    selectedCompany: PropTypes.object,
    isModalVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    isSubmitLoading: PropTypes.object
};

export default function DeleteAccountModal({ account, selectedCompany, isModalVisible, toggleModal, onDelete, isSubmitLoading }) {
    return (
        <Modal className='Support__modal' open={isModalVisible} onClose={toggleModal}>
            <h2 className='Support__modal-title'>Delete account</h2>
            <p className='Support__modal-subtitle'>Are you sure you want to delete your account?<br/>Your data will become immediately inaccessible.</p>
            <div className='Support__modal-delete'>
                { account?.image === null ? (
                    <div className='Support__modal-delete-image'>
                        <ProfileImage name={account?.name}/>
                    </div>
                ) : (
                    <img alt='Avatar' src={account?.image}/>
                )}
                <p className='Support__modal-avatar-title'>{account.name}</p>
                <p className='Support__modal-avatar-subtitle'>{selectedCompany?.name ?? ''}</p>
            </div>
            {isSubmitLoading.deleteAccount ? (
                <div className='w-100 text-center'>
                    <ActivityIndicator size={50}/>
                </div>
            ) : (
                <div className='d-flex flex-jsb'>
                    { }
                    <Button label='Cancel' className='Social__modal-cancel-btn' onClick={toggleModal}/>
                    <Button label='Delete' className='Social__modal-continue-btn' onClick={() => {
                        onDelete();
                    }}/>
                </div>
            )}
        </Modal>
    );
}
