import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SlideMenu from './SlideMenu/SlideMenu';
import 'react-responsive-modal/styles.css';
import './AppHeaderSide.scss';

export default class AppHeaderSide extends Component {
    static propTypes = {
        account: PropTypes.object,
        selectedCompany: PropTypes.object,
        isFetching: PropTypes.bool,
        companies: PropTypes.array,
        preferences: PropTypes.object,
        notifications: PropTypes.object,
        onUpdatePreference: PropTypes.func,
        onReadNotification: PropTypes.func,
        onLogout: PropTypes.func,
        onDeleteCompany: PropTypes.func,
        onDeleteAccount: PropTypes.func,
        onUpdateProfile: PropTypes.func,
        history: PropTypes.object,
        onPublishCompany: PropTypes.func,
        onUnpublishCompany: PropTypes.func,
        onQuitCompany: PropTypes.func,
        onGrantPermissions: PropTypes.func,
        onSendClientsInvite: PropTypes.func,
        onUnlinkSocial: PropTypes.func,
        onClose: PropTypes.func,
        show: PropTypes.bool,
        isSubmitLoading: PropTypes.object,
        formSuccess: PropTypes.object,
        formError: PropTypes.object,
        onItemSelect: PropTypes.func
    }

    state = {
        showNotifications: false,
        activeTab: 'list',
        showSocial: false,
        showFaq: false,
        showSupport: false,
        showClasses: false,
        showDescription: false,
        company: {}
    };

    toggleNotifications = () => {
        this.setState({
            showNotifications: !this.state.showNotifications
        });
    }

    toggleSocial = () => {
        this.setState({
            showSocial: !this.state.showSocial
        });
    }

    toggleFaq = () => {
        this.setState({
            showFaq: !this.state.showFaq
        });
    }

    toggleSupport = () => {
        this.setState({
            showSupport: !this.state.showSupport
        });
    }

    toggleDescription = (company) => {
        this.setState({
            company: company,
            showDescription: !this.state.showDescription
        });
    }

    selectTab = (tab) => {
        this.setState({
            activeTab: tab
        });
    }

    handleCloseMenu = () => {
        const { onClose } = this.props;

        onClose();
        this.setState({
            showSocial: false,
            showNotifications: false,
            showFaq: false,
            showSupport: false,
            showClasses: false,
            showDescription: false
        });
    }

    render() {
        const { showNotifications, activeTab } = this.state;
        const { show } = this.props;

        return (
            <div className='Blue-Header'>
                <SlideMenu
                    toggleNotifications={this.toggleNotifications}
                    selectTab={(val) => this.selectTab(val)}
                    showNotifications={showNotifications}
                    activeTab={activeTab}
                    showMenu={show}
                    showFaq={this.state.showFaq}
                    toggleFaq={this.toggleFaq}
                    showSupport={this.state.showSupport}
                    toggleSupport={this.toggleSupport}
                    showDescription={this.state.showDescription}
                    toggleDescription={this.toggleDescription}
                    toggleSocial={this.toggleSocial}
                    showSocial={this.state.showSocial}
                    closeMenu={this.handleCloseMenu}
                    account={this.props.account}
                    selectedCompany={this.props.selectedCompany}
                    onItemSelect={this.props.onItemSelect}
                    isFetching={this.props.isFetching}
                    companies={this.props.companies}
                    history={this.props.history}
                    preferences={this.props.preferences}
                    notifications={this.props.notifications}
                    onUpdatePreference={this.props.onUpdatePreference}
                    onReadNotification={this.props.onReadNotification}
                    onLogout={this.props.onLogout}
                    company={this.state.company}
                    onDeleteCompany={this.props.onDeleteCompany}
                    onDeleteAccount={this.props.onDeleteAccount}
                    onUpdateProfile={this.props.onUpdateProfile}
                    onPublish={this.props.onPublishCompany}
                    onUnpublish={this.props.onUnpublishCompany}
                    onQuitCompany={this.props.onQuitCompany}
                    onGrantPermissions={this.props.onGrantPermissions}
                    onSendClientsInvite={this.props.onSendClientsInvite}
                    onUnlinkSocial={this.props.onUnlinkSocial}
                    isSubmitLoading={this.props.isSubmitLoading}
                    formSuccess={this.props.formSuccess}
                    formError={this.props.formError}/>
            </div>
        );
    }
}
