import React from 'react';
import PropTypes from 'prop-types';
import Button from 'creatella-react-components/lib/Button';
import ProfileImage from '../../ProfileImage/ProfileImage';
import AppLoading from '../../../containers/App/components/Loading/AppLoading';
import Modal from '../../Modal/Modal';

DeleteCompanyModal.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    company: PropTypes.object,
    onDeleteCompany: PropTypes.func,
    isSubmitLoading: PropTypes.object
};

export default function DeleteCompanyModal({ isModalVisible, toggleModal, company, onDeleteCompany, isSubmitLoading }) {
    const handleSubmit = () => {
        if (company) {
            onDeleteCompany(company.id);
        } else {
            onDeleteCompany();
        }

        // toggleModal();
    };

    return (
        <Modal
            className='Support__modal'
            customSize={true}
            isOpen={isModalVisible}
            closeOnOverlayClick={false}
            onClose={toggleModal}>
            <h2 className='Support__modal-title'>Delete company</h2>
            <p className='Support__modal-subtitle'>
                Deleting the company will also delete all the staff members’ information.<br/>
                Are you sure you want to continue?
            </p>
            { company && (
                <div className='Support__modal-delete'>
                    { company?.logo === null ? (
                        <div className='Support__modal-delete-image'>
                            <ProfileImage name={company?.name}/>
                        </div>
                    ) : (
                        <div className='Support__modal-delete-image'>
                            <img alt='Avatar' src={company?.logo}/>
                        </div>
                    )}
                    <p className='Support__modal-avatar-title'>{company?.name ?? ''}</p>
                </div>
            )}

            {isSubmitLoading?.deleteCompany ? (
                <AppLoading/>
            ) : (
                <div className='d-flex flex-jsb'>
                    <Button label='Cancel' className='Social__modal-cancel-btn' onClick={() => toggleModal()}/>
                    <Button label='Delete' className='Social__modal-continue-btn' onClick={handleSubmit}/>
                </div>
            )}
        </Modal>
    );
}
